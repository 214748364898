.container {
    display: flex;
}

.callUs {
    display: flex;
}

.iconContainer {
    margin-right: var(--GW-SPACING-5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.phoneIcon {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
}

.infoContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.callUsInfo {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
    font-size: var(--GW-FONT-SIZE-SM);
    text-align: left;
}

.phoneInfo {
    margin-top: var(--GW-SPACING-2) !important;
    font-size: var(--GW-FONT-SIZE-MD);
    font-family: var(--GW-FONT-SERIF);
    text-align: left;
}

.callUsMobile {
    display: flex;
    padding: 1rem var(--GW-SPACING-6);
}

.phoneInfoMobile {
    padding-left: 3px;
    text-decoration: underline;
}

.buttonContainer {
    padding: 0;
    background: none;
    border: none;
    margin: 0;
    cursor: pointer;
}