@import "~@jutro/theme/assets/sass/helpers";

.digitalSLQWizard {
    --gw-wizard-page-template-sidebar-width: 28rem;
    --gw-wizard-page-padding: 0 0;
    --gw-wizard-page-buttons-bar-padding: 2rem 1rem;
    --gw-wizard-page-buttons-margin-x: 2rem;
    --gw-wizard-page-main-width: 88%;
    --gw-wizard-page-mobile-width: 100%;
    --gw-wizard-page-mobile-padding-left-right: 20px;
    --gw-slq-wizard-max-width: 584px;
    --gw-slq-wizard-padding-top: 40px;
    --gw-slq-wizard-previous-button-margin-bottom: 1rem;

    padding-top:var(--gw-slq-wizard-padding-top);
    display: grid;
    width: var(--gw-wizard-page-main-width);
    margin: 0 auto;
    grid-gap: var(--GW-SPACING-6);
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: "wiznavigation" "wizcontent" "wizbuttons";
    align-items: start;
    max-width: var(--gw-slq-wizard-max-width);

    @include gw-breakpoint-down(phoneWide) {
        grid-gap: 0;
        padding-top: 0;
    }
}

.wizardNavigation {
    --wizard-navigation-bottom-margin: 12px;
    margin-top: var(--wizard-navigation-bottom-margin);
    grid-area: wiznavigation;
}

.wizardContent {
    grid-area: wizcontent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wizardButtons {
    grid-area: wizbuttons;
    @include gw-breakpoint-up(tablet) {
        display: flex;
    }
    @include gw-breakpoint-down(tablet) {
        text-align: center;
    }
    flex-direction: row;
    justify-content: space-between;
    padding: var(--gw-wizard-page-buttons-bar-padding);
}

.wizardPreviousButton {
    @include gw-breakpoint-down(tablet) {
        width: 100%;
        margin-bottom: var(--gw-slq-wizard-previous-button-margin-bottom);
    }
}

.wizardNextButton {
    grid-area: next;
    span { 
        display: flex;
    }
    @include gw-breakpoint-down(tablet) {
        width: 100%;
    }
}
