
.collapsibleButton {
    background: none;
    border: none;
    padding: 0px;
    margin: 0px;
    height: auto;
    color: black;
    font-weight: bold;
    margin-top: 1.2rem;
    &:hover, &:focus {
        background: none;
    }
}

.collapsibleButtonCls {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.infoPanel {  
    padding: 16px;
    border: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    border-left: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);

    p {
        margin: 0px;
    }

    p.infoTitle {
        font-weight: 600;
        margin-top: 30px;
    }
}

.infoPanelExtended {
    background: var(--NFUM-BODY-BACKGROUND-COLOR-1);
}