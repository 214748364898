@import '~@jutro/theme/assets/sass/helpers';

.referralMessageContainer {
    display: flex;
    flex-direction: column;
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    padding: var(--GW-SPACING-8);

    h3.title {
        font-size: var(--NFUM-FONT-SIZE-24);
        font-family: var(--GW-FONT-SERIF);
        line-height: 2rem;
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        letter-spacing: 0.5px;
        margin-top: 1rem;
        padding-bottom: var(--GW-SPACING-5);
    }   
    
    p {
        margin: 0;
        margin-top: var(--GW-SPACING-5);
        padding-bottom: var(--GW-SPACING-4);
        line-height: 2rem;
        letter-spacing: 0.5px;
    }
}

.underlineThemeReferralMessageContainer {
    display: flex;
    flex-direction: column;
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    margin-top: -0.9rem;

    h3.title {
        border-bottom: 2px solid var(--GW-COLOR-BRAND-1);
        padding: var(--GW-SPACING-6);
        padding-left: 2rem;
    }

    .referralMessageTextContainer {
        padding: var(--GW-SPACING-6);
    }
}

.paragraph {
    margin: 0;
    margin-top: var(--GW-SPACING-5);
    padding-bottom: var(--GW-SPACING-4);
    line-height: 2rem;
    font-size: 16px;
    padding-right: 1rem;
}

.paragraphWithoutMargin {
    margin: 0;
    line-height: 2rem;
    padding-bottom: 0rem;
}

.description {
    margin-top: 2rem;
    font-weight: 100;
    font-size: 16px;
}

