@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.directDebitPageCostContainer {
    background-color: var(--NFUM-COLOR-YELLOW-MAIN);
    border: none;
    padding: 0 calc(var(--GW-SPACING-5) * 3) 0;
}

.directDebitSetupMainContainer {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    border: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-STROKE);
    margin-bottom: calc(var(--GW-SPACING-6) * 2);
}

.directDebitPageContainer {
    background-color: var(--GW-COLOR-BACKGROUND-1);

    h5 {
        margin: var(--GW-SPACING-5) calc(var(--GW-SPACING-5) * 5) var(--GW-SPACING-5) var(--GW-SPACING-5);
        font-family: var(--GW-FONT-SERIF);
        font-size: var(--GW-FONT-SIZE-MD);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        letter-spacing: 0.5px;
        line-height: var(--GW-LINE-HEIGHT);
    }

    .directDebitPayerConfirmation {
        padding-top: var(--GW-SPACING-5);
        padding-bottom: var(--GW-SPACING-5);
        border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-TEXT-1);
        margin-right: 0;
        font-family: var(--GW-FONT-SANS-SERIF);
        font-size: var(--GW-SPACING-5);
        line-height: calc(var(--GW-SPACING-4) * 2.5);
    }

    .directDebitPayerStatements {
        li {
            border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-TEXT-1);
            padding: var(--GW-SPACING-5) 0;
            font-family: var(--GW-FONT-SANS-SERIF);
            font-size: var(--GW-SPACING-5);
            line-height: calc(var(--GW-SPACING-4) * 2.5);
        }
    }

    .radioButton > div > div > div[role='radiogroup'] {
        display: flex;
        div {
            display: flex;
            align-items: center;
            box-shadow: var(--GW-SHADOW-2);
            padding: var(--GW-SPACING-4) var(--GW-SPACING-5);
            margin-bottom: var(--GW-SPACING-5);
            width: 100%;
            font-family: var(--GW-FONT-SANS-SERIF);
            line-height: calc(var(--GW-SPACING-4) * 2.5);
            letter-spacing: 0.5px;
        }
        div:last-child {
            margin-left: var(--GW-SPACING-6);
        }
    }
    .ddAutomaticRenewalOptionTitleContainer {
        display: flex;
        align-items: center;
    }
    .renewalSectionFont{
       font-size: var(--GW-FONT-SIZE-H5);
    }
}

.instructionStatementContainer {
    margin-top: calc(var(--GW-SPACING-6) * 2);
    font-family: var(--GW-FONT-SANS-SERIF);
    line-height: calc(var(--GW-SPACING-2) * 6);

    .pleaseNoteText {
        font-weight: bold;
    }
}

.directDebitPageTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--GW-SPACING-10) 0;
    border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);

    h3 {
        margin: 0 var(--GW-SPACING-5);
        font-family: var(--GW-FONT-SERIF);
        font-size: var(--GW-FONT-SIZE-MD);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        letter-spacing: 0.5px;
        line-height: var(--GW-LINE-HEIGHT);
        text-transform: none;
    }

    &.directDebitPageCostTitle {
        align-items: flex-start;
        border-bottom: 0;
        flex-direction: column;
    }
}

.directDebitPageCostValueContainer {
    display: flex;
    padding-bottom: var(--GW-SPACING-8);
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
    h4 {
      font-size: calc(var(--GW-SPACING-5) * 2.6);
      line-height: calc(var(--GW-SPACING-6) * 2);
    }

    span {
      font-size: var(--GW-SPACING-5);
      margin-top: var(--GW-SPACING-5);
    }

}

.insurancePremiumTax {
    font-size: var(--NFUM-FONT-SIZE-14);
    margin-top: var(--GW-SPACING-3);
    padding-bottom: var(--GW-SPACING-5);
    letter-spacing: 0.5px;
  }

.currentPremiumInfoContainer {
    font-size: var(--NFUM-FONT-SIZE-16);
  }

  .premiumValue {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
    
  }

  .yourPremiumText {
    margin-bottom: var(--GW-SPACING-3);
    font-family: var(--GW-FONT-SERIF) !important;
    font-size: var(--NFUM-FONT-SIZE-24) !important;
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD) !important;   
    line-height: var(--GW-LINE-HEIGHT-H5) !important;
    color: var(--GW-TEXT-COLOR-1);
    margin-top: 0;
  }

.directDebitPageSwitchPeriod {
    text-decoration: underline;
    cursor: pointer;
    margin: 0 var(--GW-SPACING-5);
}

.directDebitPageSetupDDImage {
    display: block;
    margin: var(--GW-SPACING-5);
    max-width: calc(100% - (2 * var(--GW-SPACING-5)));
}

.directDebitPageSetupDDFormAccountField {
    margin-bottom: var(--GW-SPACING-5);

    &.directDebitPageSetupDDFormAccountFieldLong {
        max-width: 100%;
    }
}

.directDebitPageSetupDDFormTermsAcceptanceBox {
    display: flex;
    align-items: center;
}

.directDebitPageSetupDDFormTermsAcceptance {
    margin-right: var(--GW-SPACING-3);
}

.directDebitPageSetupDDFormTermsAcceptanceLink {
    text-decoration: underline;
    margin-top: var(--GW-SPACING-5);
    letter-spacing: 0.5px;
}

.directDebitPageSetupDDFormTermsAcceptanceContent {
    margin-top: var(--GW-SPACING-5);
    margin-right: 4px;
}

.directDebitPageGuaranteeContainer {
    margin-top: var(--GW-SPACING-5);

}

.renewInfoContentContainer {
    border-left: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    padding: var(--GW-SPACING-5);
}

.directDebitTermsContainerInfo {
    margin-bottom: var(--GW-SPACING-6);
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.accordionCardBody {
    margin-top: var(--GW-SPACING-10);
    .valuableMargin {
      margin-top: 0;
    }
}
.accordionContainer {
    background: white;
    &:global(.isOpen) {
        .accordionCardHeader {
        background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
        }
    }
}
    
.accordionCardTitleContainer {
    padding: 0;
}

.paymentScheduleAccordion {
    :global(.jut__FieldComponent__fieldComponent) {
      margin-top: 0;
    }
    :global(.jut__FieldLabel__fieldLabelContainer) {
      margin-bottom: 0;
    }
    :global(.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRowGroup) {
        border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    }
}
.ddQuestionConfirmButton {
    div {
       span {
        text-wrap: nowrap;
       } 
    }
}

@include gw-breakpoint-down(phone) {
    :global(.rt-resizable-header-content) {
        overflow: visible !important;
    }

    :global(.rt-th) {
        padding-right: 20px !important;
    }

    :global(.rt-td) {
        padding-left: 10px !important;
    }
}

.paymentScheduleAccordion {
    :global(.rt-resizer) {
        display: none;
    }
}

h2.title {
    font-family: var(--GW-FONT-SERIF);
    margin-bottom: var(--GW-SPACING-3);
}