@import "~@jutro/theme/assets/sass/helpers";

.paymentPageCostValueContainer {
    > div {
        display: inline-flex;
        margin-top: unset;
    }
}

.paymentPageSwitchPeriod {
    text-decoration: underline;
    cursor: pointer;
}

.paymentPagePayApp {
    height: 100%;
    width: 100%;
}

.nfumIframe {
  border: none;
  width: 100%;
  height: 100%;
}

.paymentPagePaySection {
    @include gw-breakpoint-down(phone) {
        padding: 0.5rem;
        height: 48rem;
    }
    @include gw-breakpoint-down(phoneWide) {
      height: 53rem;
  }
    height: 53rem;
    margin-bottom: 2rem;
    padding: 4rem;
    background: white;
}

.editLink {
    margin-top: var(--GW-SPACING-6);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
  }
  
  .editQuoteButton {
    margin-left: var(--GW-SPACING-6);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
  }
  
  .editPremiumLinksContainer {
    display: flex;
  
    a {
      border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
      font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }
  
    a:hover {
      color: var(--GW-LINK-COLOR);
    }
  }