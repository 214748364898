@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);
$pageMaxWidth: 1500px;
$infoMaxWidth: 660px;

.securityCheckPageWrapper {
    width: 100%;
}

.securityCheckPageInfoWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.securityCheckPageContainer {
    --nfum-footer-info-padding: 5rem 12rem 5rem 12rem;

    @include gw-breakpoint-down(phone) {
        --nfum-footer-info-padding: var(--GW-SPACING-4) var(--GW-SPACING-6) var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(phoneWide) {
        --nfum-footer-info-padding: var(--GW-SPACING-10) var(--GW-SPACING-6) var(--GW-SPACING-10) var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(tablet) {
        --nfum-footer-info-padding: var(--GW-SPACING-10) 8rem var(--GW-SPACING-10) 8rem;
    }

    width: 100%;
    max-width: $pageMaxWidth;
    padding: var(--nfum-footer-info-padding);
    display: flex;
}

.rightColumn {
    @include gw-breakpoint-down(tablet) {
        flex: 0;
    }
    flex: 1
}

.leftColumn {
    flex: 3;
    max-width: $infoMaxWidth;
}

.buttonsContainer {
    margin-top: var(--GW-SPACING-6);
}

.securityCheckPageInfo {
    --nfum-referral-padding: 48px;

    @include gw-breakpoint-down(phone) {
        --nfum-referral-padding: var(--GW-SPACING-8) var(--GW-SPACING-4);
    }
    @include gw-breakpoint-only(phoneWide) {
        --nfum-referral-padding: var(--GW-SPACING-8);
    }

    grid-area: info;
    display: flex;
    flex-direction: column;
    background-color: var(--GW-COLOR-BACKGROUND-1);
    padding: var(--nfum-referral-padding);
    border-left: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-BRAND-1);

    p {
        margin: 0;
        margin-top: var(--GW-SPACING-5);
        padding-bottom: var(--GW-SPACING-4);
        line-height: 2rem;
    }

    .title {
        font-size: var(--NFUM-FONT-SIZE-20);
        font-family: var(--GW-FONT-SERIF);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }

    .additionalTextContainer {
        border-style: solid;
        border-width: var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN)
            var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH);
        border-color: var(--GW-BRAND-COLOR-2) var(--GW-BRAND-COLOR-2)
            var(--GW-BRAND-COLOR-2) var(--GW-COLOR-BRAND-1);
        font-family: var(--GW-FONT-SANS-SERIF);
        padding: var(--GW-SPACING-3) 0 var(--GW-SPACING-3) var(--GW-SPACING-3);
        margin-top: var(--GW-SPACING-2);
        line-height: calc(var(--GW-SPACING-3) * 3.9);
        background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    }

    .link {
        text-decoration: underline;
    }

    .bold {
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }
}
