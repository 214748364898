@import "~@jutro/theme/assets/sass/helpers";

.retrieveQuoteButtonContainer {
  --retrieve-quote-button-container-margin-x: var(--GW-SPACING-6);
  --retrieve-quote-button-margin-x: var(--GW-SPACING-2);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: var(--retrieve-quote-button-container-margin-x) 0;
   

  @include gw-breakpoint-down(tablet) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    order: 0;

    & button {
      margin: var(--retrieve-quote-button-margin-x) 0;
    }
  }
}

.retrieveQuoteAdditionalButtonContainer {
  display: flex;
  @include gw-breakpoint-down(tablet) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    order: 1;
  }
}

p.errorMessage {
  color: var(--GW-COLOR-ERROR);
  margin-bottom: 0;
  margin-top: var(--GW-SPACING-4);
  padding-top: 25px;
}

.erroredInput {
  :global(input) {
    border-color: var(--GW-COLOR-ERROR);
  }
}

.postcodeAndDateOfBirthInput {
  width: 60%;
  margin-bottom: 1rem;
  :global(.jut__SimpleDateField__hidden) {
    clip: auto;
    overflow: visible;
    padding: 0;
    position: absolute;
    width: auto;
    padding-top: 45px;
    font-size: 12px;
    font-weight: bold;
    margin-left: 11px;
  }
  :global(.jut__OldFieldMessage__error) {
    margin-top: 25px;
  }
  @include gw-breakpoint-down(tablet) {
    width:100%;
  }
}

.retrieveQuoteStartNewButton {
  margin-right: var(--GW-SPACING-6);
}
.labelMargin{
  margin-top: 0;
}
