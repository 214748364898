@import '~@jutro/theme/assets/sass/helpers';
.successContainer {
    p {
        line-height: var(--GW-SPACING-8) !important;
        margin: var(--GW-SPACING-5) 0 !important;
    }
}

.transactionContainer {
    background-color: var(--GW-BRAND-COLOR-1);
    padding: var(--GW-SPACING-6);
}  

h2.transactionTitle {
    font-family: var(--GW-FONT-SERIF);
    margin-bottom: var(--GW-SPACING-6);
    font-size: var(--GW-FONT-SIZE-LG);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}  

p.transactionText {
    line-height: var(--GW-SPACING-8);
    margin-top: var(--GW-SPACING-3);
    margin-bottom: var(--GW-SPACING-6);
}

.policyNumberLabel {
    margin-bottom: var(--GW-SPACING-3);
}

.effectiveDateLabel {
    margin-bottom: var(--GW-SPACING-3);
}

.policyNumberContainer {
    border-top: 1px dashed black;
    padding-top: var(--GW-SPACING-6);
    padding-bottom: var(--GW-SPACING-6);
}

.effectiveDateContainer {
    border-top: 1px dashed black;
    padding-top: var(--GW-SPACING-6);
}

.buttonsInfoContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--GW-SPACING-10);
    @include gw-breakpoint-only(phone){
        margin-bottom: var(--GW-SPACING-10);
    }
    & .returnButton:hover i {
        color: var(--NFUM-COLOR-WHITE);
    }
}
