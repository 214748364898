@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.valuablesCtaDescriptionAndButtonWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--GW-SPACING-4);
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.bold {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

.valuablesCtaDescriptionWrapper {
    width: 60%;
    .valuableReplacementValue {
        font-weight: var(--GW-FONT-WEIGHT-BOLD);
    }
}

.valuablesCtaButtonWrapper {
    margin-left: calc(var(--GW-SPACING-6) * 3);
    @include gw-breakpoint-only(phone){
        margin-left: var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(phoneWide){
        margin-left: var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(tablet){
        margin-left: var(--GW-SPACING-6);
    }
}

.actionCell {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    a {
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
        margin-right: var(--GW-SPACING-4);
    }
}

.valuableAwayFromHomeMessageContainer {
    border-top: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
    background-color: var(--GW-COLOR-BACKGROUND-4);
    padding: var(--GW-SPACING-6);
    letter-spacing: 0.5px;

    h5 {
        font-family: var(--GW-FONT-SERIF);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        font-size: calc(var(--GW-SPACING-4)*1.5);
        line-height: calc(var(--GW-SPACING-4)*3); 
    }
    
    p {
        font-family: var(--GW-FONT-SANS-SERIF);
        font-size: calc(var(--GW-SPACING-3)*2);
    }
}

.riskObjectBox {
    margin-bottom: var(--GW-SPACING-6);;
}

.riskObjectTitle {
    background: var(--NFUM-COLOR-FIELD-DISABLED);
    padding: var(--GW-SPACING-5) var(--GW-SPACING-4);
    border-top: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY);
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 700;
    margin: 0;
    margin-top: var(--GW-SPACING-6);
}

.riskObjectPropertyLabel {
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 400;
}

.riskObjectProperty {
    padding-left: var(--GW-SPACING-4);
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 400;
    padding-top: 14px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);

    &:global(.jut__FieldComponent__fieldComponent) {
        border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
        padding-bottom: var(--GW-SPACING-4);
        padding-top: var(--GW-SPACING-4);
        margin-top: 0;
        max-width: 100%;
    }
}

.riskObjectSubtitle {
    padding-left: var(--GW-SPACING-4);
    font-size: var(--NFUM-FONT-SIZE-16);
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    padding-bottom: var(--GW-SPACING-4);
    padding-top: var(--GW-SPACING-4);
    margin: 0;
}

.itemActionsWrapper {
    margin-top: var(--GW-SPACING-4);
    margin-left: var(--GW-SPACING-4);
    display: flex;
}

.editButton {
    margin-right: var(--GW-SPACING-6);
}