@import "~@jutro/theme/assets/sass/helpers";
.modalWrapper {
    display: flex;
    justify-content: center;
}

.modal {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    overflow-y: auto;
    padding: 0;
    max-height: 90vh;
    max-width: 40%;
    margin: var(--GW-SPACING-4);
    @include gw-breakpoint-only(phone){
        max-width: 100%;
    }
    @include gw-breakpoint-only(phoneWide){
        max-width: 60%;
    }
}

.modalHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: var(--GW-SPACING-6) var(--GW-SPACING-10) var(--GW-SPACING-6) var(--GW-SPACING-10);
    position: sticky;
    background: var(--GW-COLOR-BACKGROUND-1);
    top: 0;
    left: 0;
    z-index: 101;
    h5 {
        font-family: var(--GW-FONT-SERIF);
        font-size: 24px;
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        letter-spacing: 0.5px;
        line-height: var(--GW-LINE-HEIGHT);
        text-transform: none;
        @include gw-breakpoint-only(phone){
            font-size: 20px;
        }
    }

    span {
        flex-shrink: 0;
    }
}

.modalTitle {
    flex: 1;
}

.modalHeaderBorderBottom {
    border-bottom: calc(var(--GW-BORDER-WIDTH)) solid var(--NFUM-COLOR-YELLOW-MAIN);
}

.modalContent {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-10) var(--GW-SPACING-6) var(--GW-SPACING-10);
    font-size: 16px;
    line-height: 30px;
}

.modalFooter {
    padding: 0 var(--GW-SPACING-10) var(--GW-SPACING-10) var(--GW-SPACING-10);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

ul.removingContentsContainer {
    margin: 16px 0px;
    padding: 0px 20px;
    li p {
        padding-bottom: 0px;
        line-height: inherit;
    }
}