@import '~@jutro/theme/assets/sass/helpers';

.costInfoTableContainer {
    border-top: 1px solid #2D2C2C;
}

.costInfoTitle {
    border-top: 1px solid #2D2C2C;
    border-bottom: 1px solid #2D2C2C;
    margin-top: 8px;
    padding: 8px;
    padding-top: 0px;
}

.costInfoTitleText {
    color: #2D2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.costInfoTableHeader {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    border-bottom: 1px solid #2D2C2C;
}

.costInfoCoverNameHeader {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.5px;
    width: 33%;
    @include gw-breakpoint-only(phone){
        width: 100%;
    }
}

.costInfoAmountHeader {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.5px;
    width: 33%;
    text-align: center;
    @include gw-breakpoint-only(phone){
        width: 100%;
        text-align: right;
    }
}