.wizardSidebarWrapper {
  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
  text-align: left;
  background-color: var(--GW-BACKGROUND-COLOR);
  border-radius: var(--GW-BORDER-RADIUS);
  box-shadow: var(--GW-SHADOW-2);
}

.step {
  display: block;
  cursor: pointer;
  margin-bottom: var(--GW-SPACING-2);
  border-radius: var(--GW-BORDER-RADIUS);

  &.step:hover {
    text-decoration: var(--GW-TEXT-DECORATION-UNDERLINE);
  }

  &.active {
    color: var(--GW-COLOR-SUCCESS-CONTRAST);
    background-color: var(--GW-BRAND-COLOR-1);
    position: relative;

    &.active:hover {
      text-decoration: var(--GW-NO-TEXT-DECORATION);
    }
  }

  &.active::after {
    content: "";
    width: 0;
    height: 0;
    z-index: 2;
    border-right: var(--GW-SPACING-3) solid var(--GW-COLOR-SUCCESS-CONTRAST);
    border-bottom: var(--GW-SPACING-3) solid transparent;
    border-top: var(--GW-SPACING-3) solid transparent;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &.notVisited {
    color:  var(--GW-SEPARATOR-COLOR);
    cursor: not-allowed;

    .navigationButton {
      pointer-events: none;
    }

    &.notVisited:hover {
      text-decoration: var(--GW-NO-TEXT-DECORATION);
    }
  }

  .navigationButton {
    border: none;
    background: inherit;
    cursor: pointer;
    color: inherit;
    line-height: var(--GW-LINE-HEIGHT-BUTTON);
    padding: var(--GW-LAYOUT-1) var(--GW-LAYOUT-2);

    &.navigationButton:focus{
      outline: none;
    }
  }
}

.warningIssue {
  color: var(--GW-COLOR-WARNING);
}

.errorIssue {
  color: var(--GW-COLOR-ERROR);
}

.stepsNav {
  list-style: none;
  text-align: left;
  padding: 0;
}

ul[class*='stepsNav'] {
  margin: 0;
}

h3.sideBarTitle {
  padding-bottom: var(--GW-SPACING-4);
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
  margin-bottom: var(--GW-SPACING-4);
}
