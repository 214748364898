@import '~@jutro/theme/assets/sass/helpers';
.referralMessageContent {
  display: flex;
  flex-direction: column;
  letter-spacing: 0.5px;

    p {       
        font-size: var(--NFUM-FONT-SIZE-16);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        font-family: var(--GW-FONT-SANS-SERIF);
        line-height: 24px;
        margin: 0;
        margin-bottom: var(--GW-SPACING-5);
    }

    .referralMessageTitle {
        font-size: var(--NFUM-FONT-SIZE-24);
        font-family: var(--GW-FONT-SERIF);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        line-height: 40px;
        margin-bottom: var(--GW-SPACING-5);
    }
}
.callAndAgencyContainerWrapper {
    grid-area: info;
    display: flex;
    flex-direction: column;
    // padding: var(--nfum-referral-padding);
}

.alteranteContactOptionContainer {
    border: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    font-family: var(--GW-FONT-SANS-SERIF);
    padding: var(--GW-SPACING-3) 0 var(--GW-SPACING-3) var(--GW-SPACING-3);
    margin-top: var(--GW-SPACING-6);
    line-height: calc(var(--GW-SPACING-3) * 3.9);

    .requestForCallbackText {
      text-decoration: underline;
      cursor: pointer;
    }
}

.callAndAgencyContainer {
  display: flex;
  justify-content: space-between;
  @include gw-breakpoint-only(phone){
      margin-top: var(--GW-SPACING-6);
  }
}

.findYourAgencyContainer,
.contactYourAgencyContainer,
.callTimingsContainer,
.callBackContainer {
  flex: 1;
  border: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);
  padding: var(--GW-SPACING-6);
  margin: var(--GW-SPACING-2);

  & i.phoneIcon,
  i.findYourAgencyIcon {
      float: left;
      color: var(--GW-COLOR-INFO-CONTRAST);
      border-radius: 100%;
      padding: var(--GW-SPACING-3);
      background-color: var(--GW-COLOR-BRAND-1);
  }

  h5 {
    float: left;
    margin: var(--GW-SPACING-3) 0;
    font-size: var(--NFUM-FONT-SIZE-20);
    font-family: var(--GW-FONT-SERIF);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    line-height: 30px;
    letter-spacing: 0.5px;
    margin-bottom: var(--GW-SPACING-5);
    text-transform: none;
    @media (max-width: 400px) {
      font-size: var(--NFUM-FONT-SIZE-16);
    }
  }

  p, ul {
      clear: both;
  }
}

.findYourAgencyButton,
.callBackButton {
    padding: 0px;
    margin-top: var(--GW-SPACING-5);
    span {
        padding-bottom: var(--GW-SPACING-2);
        letter-spacing: 0.5px;
        font-size: var(--NFUM-FONT-SIZE-16);
    }
}

.callTimingsDetails {
  padding: 0 var(--GW-SPACING-5);
  padding-top: var(--GW-SPACING-3);

  li {
      margin: var(--GW-SPACING-4) 0;
  }
}

p.findYourAgencyText,
p.callBackText
{
    line-height: 2rem;
    padding-top: var(--GW-SPACING-5);
    padding-left: 0;
    margin-bottom: var(--GW-SPACING-5);
}

.findYourAgencyContainer,
.callBackContainer {
    padding-left: var(--GW-SPACING-6);
}

.contactYourAgencyIcon{
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  margin-top:var(--GW-SPACING-5);
}
.contactYourAgencyButton {
  padding: 0px;
  margin-left: 25%;
  float: right;
  width:20%;
  @include gw-breakpoint-down(phone) {
      margin-left: 0;
      display: none;
  }
}

.contactYourAgencyText,
.contactYourAgencyCallus {
  padding: var(--GW-SPACING-5);
  font-size: var(--NFUM-FONT-SIZE-18) !important;
  font-family: var(--GW-FONT-SERIF) !important;
  font-weight: var(--GW-FONT-WEIGHT-MEDIUM)!important;
  line-height: 30px !important;
  letter-spacing: 0.5px !important;
}

.contactYourAgencyCallus {
  padding: 0;
  width:40%;
  flex:none;
}

.iconContainer {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--GW-SPACING-4);
}

.phoneIcon {
    width: 56px;
    height: 56px;
    composes: circle_contact from global;
    background-repeat: no-repeat;
    background-size: contain;
}

.arrangeIcon {
    width: 56px;
    height: 56px;
    composes: circle_call_back from global;
    background-repeat: no-repeat;
    background-size: contain;
}

.agencyIcon {
    width: 56px;
    height: 56px;
    composes: circle_agency from global;
    background-repeat: no-repeat;
    background-size: contain;
}

.phoneAgencyIcon {
    height: 24px;
    width: 5%;
    composes: circle_contact from global;
    background-repeat: no-repeat;
    background-size: contain;
    margin: var(--GW-SPACING-1);
}

