@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.highValueBicycleCta {
    display: flex;
    justify-content: space-between;
    &.highValueBicycleCtaDescriptionWrapper {
        width: 60%; 
        .highValueBicycleValue {
            font-weight: var(--GW-FONT-WEIGHT-BOLD);
        }
    }
}

.bold {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

.highValueBicycleCtaButtonWrapper {
    margin-left: calc(var(--GW-SPACING-6) * 3);
    @include gw-breakpoint-only(phone){
        margin-left: var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(phoneWide){
        margin-left: var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(tablet){
        margin-left: var(--GW-SPACING-6);
    }
}

.actionCell {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    a {
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
        margin-right: var(--GW-SPACING-4);
    }
}

.riskObjectBox {
    margin-bottom: var(--GW-SPACING-6);;
}

.riskObjectTitle {
    background: var(--NFUM-COLOR-FIELD-DISABLED);
    padding: var(--GW-SPACING-5) var(--GW-SPACING-4);
    border-top: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY);
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 700;
    margin: 0;
    margin-top: var(--GW-SPACING-6);
}

.riskObjectPropertyLabel {
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 400;
}

.riskObjectProperty {
    padding-left: var(--GW-SPACING-4);
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 400;
    padding-top: 14px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);

    &:global(.jut__FieldComponent__fieldComponent) {
        border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
        padding-bottom: var(--GW-SPACING-4);
        padding-top: var(--GW-SPACING-4);
        margin-top: 0;
    }
}

.riskObjectSubtitle {
    padding-left: var(--GW-SPACING-4);
    font-size: var(--NFUM-FONT-SIZE-16);
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    padding-bottom: var(--GW-SPACING-4);
    padding-top: var(--GW-SPACING-4);
    margin: 0;
}

.itemActionsWrapper {
    margin-top: var(--GW-SPACING-4);
    margin-left: var(--GW-SPACING-4);
    display: flex;
}

.editButton {
    margin-right: var(--GW-SPACING-6);
}

.ebikesTooltip {
    margin-top: -30px;
    margin-right: -15px;
    label > div {
        width: 100%;
    }
}

.highValueBicycleSecurityContainer {
    padding-right: var(--GW-SPACING-8);
    padding-left: var(--GW-SPACING-8);
    font-size: 16px;
    margin-top: 2rem;
    background-color: #FFF5D1;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include gw-breakpoint-only(phone){
        padding: var(--GW-SPACING-5);
        margin-top: var(--GW-SPACING-6);
    }
}

.highValueBicycleSecurityDescriptionCls{
    border-bottom: calc(var(--GW-BORDER-WIDTH)) solid var(--NFUM-COLOR-YELLOW-MAIN);
    padding-top: 0.8rem;
}

.highValueBicycleSecurityDescription2Cls {
    padding-top: 0.8rem;
    text-align: center;
}