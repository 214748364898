@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.nfumInputContainer {
    .nfumInput {
        display: inline-block;
        @include gw-breakpoint-down(phone) {
            display: inline;
        }
        .nfumInputField {
            border: 1px solid #757575;
            margin: auto;
            width: 100%;
            max-width: 180px;
            
            button {
                background: none;
                border: none;
                box-shadow: none;
            }
            button:hover {
                background-color: var(--NFUM-COLOR-YELLOW-MAIN);
            }
            button:active {
                background-color: var(--NFUM-COLOR-DARK);
                color: var(--NFUM-COLOR-YELLOW-MAIN);
            }
            button[aria-disabled]{
                background-color: #DDDDDD;
                color: #757575;
            }
            button.jut__Button__outlined:hover .jut__Button__icon { 
                color: #2D2C2C;
            }
        }
    
        .errorIcon {
            color: var(--NFUM-COLOR-ERROR);
        }
    
        .nfumInputIcon {
            margin-top: var(--GW-SPACING-8);
            margin-left: var(--GW-SPACING-3);
        }

        > div:first-child {
            @include gw-breakpoint-down(phone) {
                margin-top: var(--GW-SPACING-4);
            }
        }        
    }

}