@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.maxWidth {
    width: 60%;
    @include gw-breakpoint-only(phone){
        width:100%;
    }
    @include gw-breakpoint-only(phoneWide){
        width:100%;
    }
    @include gw-breakpoint-only(tablet){
        width:100%;
    }
}