@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.riskObjectBox {
    margin-bottom: var(--GW-SPACING-6);;
}

.riskObjectTitle {
    background: var(--NFUM-COLOR-FIELD-DISABLED);
    padding: var(--GW-SPACING-5) var(--GW-SPACING-4);
    border-top: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY);
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 700;
    margin: 0;
}

.riskObjectPropertyContainer {
    padding-left: var(--GW-SPACING-4);
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    padding-bottom: var(--GW-SPACING-4);
    padding-top: var(--GW-SPACING-4);

    @include gw-breakpoint-only(phone){
        padding-left: var(--GW-SPACING-0);
    }
}

.riskObjectPropertyValue {
    padding-left: var(--GW-SPACING-4);

    @include gw-breakpoint-only(phone){
        padding-left: var(--GW-SPACING-0);
    }
}

.riskObjectSubtitle {
    padding-left: var(--GW-SPACING-4);
    font-size: var(--NFUM-FONT-SIZE-16);
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    padding-bottom: var(--GW-SPACING-4);
    padding-top: var(--GW-SPACING-4);
    margin: 0;
    @include gw-breakpoint-only(phone){
        padding-left: var(--GW-SPACING-0);
    }
}