@font-face {
  font-family: 'Archer SSm';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src:
    url('#{$archer-book-root}/WOFF/ArcherSSm-Book.woff2') format('woff2'),
    url('#{$archer-book-root}/WOFF/ArcherSSm-Book.woff') format('woff'),
    url('#{$archer-book-root}/TTF/ArcherSSm-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Archer SSm';
  font-weight: 500 600;
  src:
    url('#{$archer-book-root}/OTF/Archer-Medium.otf') format('opentype')
}

@font-face {
  font-family: 'Archer SSm';
  font-weight: 700;
  src:
    url('#{$archer-book-root}/OTF/Archer-Semibold.otf') format('opentype')
}

@font-face {
  font-family: 'Archer SSm';
  font-weight: 800 900;
  src:
    url('#{$archer-book-root}/OTF/Archer-Bold.otf') format('opentype')
}
