.wrapper {
    button {
        --button-primary-bg-color: var(--GW-COLOR-INFO);
        --button-primary-hover-bg-color: var(--GW-COLOR-WARNING-CONTRAST);
        --button-primary-active-bg-color: var(--GW-COLOR-WARNING-CONTRAST);
        --button-primary-focus-bg-color: var(--GW-COLOR-INFO);
    }

    --gw-custom-landing-page-spacing: 3.5rem;
    --gw-custom-landing-page-section-spacing: var(--gw-custom-landing-page-spacing) auto;

    .layer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .heading {
        font-size: var(--GW-FONT-SIZE-H2);
    }

    .description {
        font-size: var(--GW-FONT-SIZE-H3);
    }
}

.firstLayer  {
    --gw-custom-landing-page-padding: 10rem 0;
    background: url(../../../public/segmentation-examples/night.jpg) no-repeat 0 0;
    background-color: var(--GW-COLOR-INFO);
    background-attachment: fixed;
    padding: var(--gw-custom-landing-page-padding);

    .strapline {
        color: var(--GW-COLOR-INFO-CONTRAST);
        font-size: var(--GW-FONT-SIZE-H1);
    }
    .subline {
        color: var(--GW-COLOR-INFO-CONTRAST);
        font-size: var(--GW-FONT-SIZE-H3);
    }
}

.carousel {
    --gw-custom-landing-page-carousel-height: 500px;
    & > div  {
        height: var(--gw-custom-landing-page-carousel-height);

        & img {
            vertical-align: bottom;
        }
    }
}

.secondLayer {
    --gw-custom-landing-page-second-section-width: 30%;
    --gw-custom-landing-page-second-section-height: 200px;
    width: var(--gw-custom-landing-page-second-section-width);
    height: var(--gw-custom-landing-page-second-section-height);
    margin: var(--gw-custom-landing-page-section-spacing);
    &.layer {
        flex-direction: row;
    }

    .leftSection {
        --gw-custom-landing-page-second-section-image-width: 130px;
        --gw-custom-landing-page-second-section-image-height: 200px;
        width: var(--gw-custom-landing-page-second-section-image-width);
        height: var(--gw-custom-landing-page-second-section-image-height);
        background: url(../../../public/segmentation-examples/graph.svg) no-repeat 0 0;
        background-position-y: center;
    }

    .rightSection {
        --gw-custom-landing-page-second-section-text-width: 80%;
        width: var(--gw-custom-landing-page-second-section-text-width);
    }
}

.thirdLayer {
    background-color: var(--GW-FOCUS-COLOR);
    color: var(--GW-COLOR-INFO-CONTRAST);
    padding: var(--gw-custom-landing-page-spacing);

    &.layer {
        flex-direction: row;
    }

    .blockTitle {
        --gw-custom-landing-page-third-section-block-width: 225px;
        --gw-custom-landing-page-third-section-block-height: 90px;
        width: var(--gw-custom-landing-page-third-section-block-width);
        height: var(--gw-custom-landing-page-third-section-block-height);
        @extend .layer;

        &:hover {
            background-color: var(--GW-BACKGROUND-COLOR);
        }

        .blockTitleIcon {
            --gw-custom-landing-page-third-section-block-icon-margin: 2rem;
            font-size: var(--GW-FONT-SIZE-H1);
            margin-bottom: var(--gw-custom-landing-page-third-section-block-icon-margin);
        }

        .blockTitleText {
            font-size: var(--GW-FONT-SIZE-H3);
        }
    }
}

.fourthLayer {
    background-color: var(--GW-LINK-COLOR-ACTIVE);
    color: var(--GW-COLOR-INFO-CONTRAST);
    padding: var(--gw-custom-landing-page-spacing);

    .fourthLayerContent {
        --gw-custom-landing-page-second-section-max-width: 900px;
        --gw-custom-landing-page-second-section-width: 90%;
        max-width: var(--gw-custom-landing-page-second-section-max-width);
        width: var(--gw-custom-landing-page-second-section-width);

        .heading {
            color: var(--GW-TEXT-COLOR-3);
            text-align: center;
        }
    }
}

.fifthLayer {
    margin: var(--gw-custom-landing-page-section-spacing);

    .quoteMark {
        color: var(--GW-COLOR-INFO);
        font-size: var(--GW-FONT-SIZE-SUBTEXT);
        vertical-align: top;
    }
    .quotation {
        --gw-custom-landing-page-quote-margin: 5px;
        margin-left: var(--gw-custom-landing-page-quote-margin);
        font-size: var(--GW-FONT-SIZE-H2);
    }

    .quotationCite {
        font-size: var(--GW-FONT-SIZE-H4);
        font-style: italic;

        &::before {
            content: '-'
        }
    }
}

.sixthLayer {
    color: var(--GW-COLOR-INFO-CONTRAST);
    position: relative;
    background-color: var(--GW-COLOR-INFO);
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background-image: url(../../../public/segmentation-examples/house_sunset.jpg);
        background-position: center center;
        background-attachment: fixed;
    }

    .sixthLayerContent {
        padding: var(--gw-custom-landing-page-spacing);
    }

    .heading {
        color: var(--GW-COLOR-INFO-CONTRAST);
        text-align: center;
    }

    .bulletpoint {
        --gw-custom-landing-page-bulletpoint-container-width: 40%;
        --gw-custom-landing-page-bulletpoint-container-columns: 2;
        column-count: var(--gw-custom-landing-page-bulletpoint-container-columns);
        width: var(--gw-custom-landing-page-bulletpoint-container-width);
        margin: auto;

        .bulletPointValue {
            color: var(--GW-COLOR-INFO-CONTRAST);
        }
    }
}

.seventhLayer {
    margin: var(--gw-custom-landing-page-section-spacing);
}

.eightLayer {
    padding: var(--gw-custom-landing-page-spacing);
    background-color: var(--GW-COLOR-INFO);
}

.ninthLayer {
    background-color: var(--GW-TEXT-COLOR-1);
    padding: var(--gw-custom-landing-page-spacing);
    color: var(--GW-COLOR-INFO-CONTRAST);
}
