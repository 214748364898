@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.specialConditionsContainer {
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1) !important;
}

.conditionsAccordion {
    :global(.jut__Accordion__accordionFrame) {
        margin-bottom: 10px;
        border-top: var(--GW-BORDER-WIDTH) solid #9f9f9f !important;
        border-bottom: var(--GW-BORDER-WIDTH) solid #9f9f9f !important;
    }

    :global(.jut__Accordion__accordionToggle) {
        padding: 0px;
    }

    :global(.jut__Accordion__cardBody) {
        margin: 0px 10px 20px 10px;
    }
}

.simpleAccordionTitleContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px 10px;

    h3 {
        font-size: var(--NFUM-FONT-SIZE-16);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    }
}