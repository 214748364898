.fineArtContainer {
    > .title {
        font-size: var(--GW-FONT-SIZE) !important;
        padding: var(--GW-SPACING-6);
    }

    border-bottom: 1px solid var(--NFUM-TABLE-HEADER-BACKGROUND-COLOR);
}

.riskLocationTitle {
    background: var(--NFUM-COLOR-LIGHT-GREY);
    padding: var(--GW-SPACING-5) var(--GW-SPACING-4);
    border-bottom: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY);
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 700;
    margin: 0;
}

.scheduleItemProperty {
    padding: var(--GW-SPACING-3) var(--GW-SPACING-6);
    margin-top: 0px;
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    font-size: var(--GW-FONT-SIZE);
    :global(.jut__DatePickerField__readOnly) {
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    padding: var(--GW-SPACING-3) var(--GW-SPACING-6);
    font-size: var(--GW-FONT-SIZE);
    }
}

.headerDescription {
    overflow: hidden;
}

.tableHeader{
    background-color: var(--NFUM-TABLE-HEADER-BACKGROUND-COLOR);
    font-size: var(--NFUM-FONT-SIZE-16);
    padding: var(--GW-SPACING-6);
}

.tableHeader table>thead>tr>th{
    background-color:  var(--NFUM-COLOR-LIGHT-GREY) !important;
    font-size: var(--NFUM-FONT-SIZE-16) !important;
    padding: var(--GW-SPACING-5) !important;
}

.tableHeader table>thead>tr>td{
    background: var(--NFUM-COLOR-LIGHT-GREY);
    padding: var(--GW-SPACING-5) var(--GW-SPACING-4);
    border-bottom: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY) !important;
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 700;
    margin: 0;
}

.tableHeader table>tbody>tr>td{
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY) !important;
    padding: var(--GW-SPACING-5);
    font-size: var(--NFUM-FONT-SIZE-16);
    color: var(--NFUM-FONT-COLOR-PRIMARY) !important;
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
}
