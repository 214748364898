@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.nfumFieldLabelContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-right: 0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 0;
    margin-top: var(--GW-SPACING-5);
    pointer-events: none;
    .primaryLabel {
        margin-right: 4px; // UX: address hardcoded-spacing
    }

    .nfumFieldLabel {
        color: var(--NFUM-FONT-COLOR-PRIMARY);
        font-weight: var(--GW-FIELD-COMPONENT-FONT-WEIGHT);
        display: inline-block;
        position: relative;

        
        @include typography-label;
        
        @include gw-breakpoint-up(tablet) {
            word-wrap: break-word;
        }
        
        button[class*='tooltip'] {
            margin-left: 4px; // UX: address hardcoded-spacing
        }
        }

    .requiredSymbol {
        color: var(--GW-FIELD-COMPONENT-REQUIRED-SYMBOL-COLOR);
        font-weight: var(--GW-FONT-WEIGHT-BOLD);
        margin-right: var(--GW-SPACING-1);
    }

    .tooltipIcon {
        font-size: var(--GW-ICON-FONT-SIZE-3);
        line-height: var(--GW-ICON-LINE-HEIGHT);
        color: var(--NFUM-FONT-COLOR-PRIMARY);
        padding: 0;
        display: inline-flex;
        background-color: transparent;
        border: none;
        // border: 2px solid #606060;
        outline: none;
        align-self: flex-start;
        margin-left: 0.25rem;
        position: absolute;
        height: 1.56rem;
        width: 1.56rem;
        pointer-events: auto;
        border-radius: 50%;
        background-position: center;
        cursor: pointer;
        > i {
            position: relative;
            width: auto;
        }
        > i:focus {
            border-radius: 50%;
            outline: none;
            box-shadow: 0 0 0 2px var(--NFUM-COLOR-YELLOW-MAIN);
        }
        .tooltipVisible {
            border-radius: 50%;
            background-color: var(--NFUM-COLOR-YELLOW-MAIN);
        }
    }
}

.tooltipIcon:focus{
    border: 2px solid #606060;
    outline: none;
}

.tooltipContainer button:focus{
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-GREY);
    outline: none;
}
.radioButtonTooltipContainer button:focus{
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-GREY);
    outline: none;
}

.tooltipContainer {
    border: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    border-left: 2px solid var(--NFUM-BORDER-LEFT-COLOR);
    width: auto;
    padding: var(--GW-SPACING-6);
    margin-top: var(--GW-SPACING-3);

    .tooltipText {
        padding-bottom: var(--GW-SPACING-6);
    }
    .tooltipBold {
        font-weight: bold;
        font-size: initial;
    }
    @include gw-breakpoint-only(phone){
      max-width: 100%;
    }
    @include gw-breakpoint-only(phoneWide){
        max-width: 100%;
    }
    @include gw-breakpoint-only(tablet){
        max-width: 100%;
    }
}

.tooltipHidden {
    display: none;
}

.rightTooltip {
    width: 72% !important;
    display: inline-block;
}

div.noMargin {
    margin-top: 0px;
}

div.rightMargin {
    margin-top: -24px;
    margin-right: -9rem;
    @include gw-breakpoint-only(phone) {
        margin-right: 0;
    }
}

.radioButtonTooltipContainer {
    border: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    border-left: 2px solid var(--NFUM-BORDER-LEFT-COLOR);
    max-width: 100%;
    width: auto;
    padding: var(--GW-SPACING-6);
    margin-bottom: 1rem;
    margin-top: 1rem;
    
    .tooltipText {
        padding-bottom: var(--GW-SPACING-6);
    }
    .tooltipBold {
        font-weight: bold;
        font-size: initial;
    }
}
