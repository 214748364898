@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.accordionChevronIsClosed {
    background: var(--NFUM-COLOR-YELLOW-MAIN);
    border-radius: 50%;
    margin-left: var(--GW-SPACING-5);
    height: 50%;
    border: 3px solid var(--NFUM-COLOR-YELLOW-MAIN);
    transform: rotate(90deg);
}
.accordionChevronContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: var(--NFUM-COLOR-YELLOW-MAIN);
    border-radius: 50%;
    position: relative;
    padding:0.20em;
    margin-left:0.5em;
  }
  .accordionCloseMessageContainer {
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.rotatedIcon {
    rotate: calc(90deg);
}

.arrowIcon{
    font-size: 1.7rem;
    &:hover {
        background-color: transparent;
    }

    @include gw-breakpoint-down(phone) {
        font-size: 1rem;
    }
}

.accordionTitleContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-YELLOW-MAIN);
    width: 100%;
    padding: 24px 72px;
    @include gw-breakpoint-down(phone) {
        padding: var(--GW-SPACING-5) var(--GW-SPACING-5);
    }

    h3 {
        font-family: var(--GW-FONT-SERIF);
        font-size: var(--NFUM-FONT-SIZE-24);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        @include gw-breakpoint-only(phone){
            font-size: var(--GW-FONT-SIZE-H4);
        }
    }

    .accordionOpenMessage {
        font-size: var(--NFUM-FONT-SIZE-18);
        margin-left: auto;
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        @include gw-breakpoint-only(phone){
            font-size: var(--NFUM-FONT-SIZE-15);
        }
    }
}

.accordionChevronIsOpen {
    background: var(--NFUM-COLOR-YELLOW-MAIN);
    border-radius: 50%;
    margin-left: var(--GW-SPACING-5);
    height: 50%;
    border: 3px solid var(--NFUM-COLOR-YELLOW-MAIN);
    transform: rotate(-90deg);
}