@font-face {
  font-family: 'Meta Pro';
  font-weight: 200 400;
  font-style: normal;
  font-stretch: normal;
  src:
    url('#{$meta-pro-root}/WOFF/MetaPro-Normal.woff2') format('woff2'),
    url('#{$meta-pro-root}/WOFF/MetaPro-Normal.woff') format('woff'),
    url('#{$meta-pro-root}/TTF/MetaPro-Normal.ttf') format('truetype');
}

@font-face {
  font-family: 'Meta Pro';
  font-weight: 500 900;
  font-style: bold;
  font-stretch: normal;
  src:
    url('#{$meta-pro-root}/WOFF/MetaPro-Bold.woff2') format('woff2'),
    url('#{$meta-pro-root}/WOFF/MetaPro-Bold.woff') format('woff'),
    url('#{$meta-pro-root}/TTF/MetaPro-Bold.ttf') format('truetype');
}
