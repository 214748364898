@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.spacing {
    padding: var(--GW-SPACING-3) 0 var(--GW-SPACING-3) 0;
}

.aboutOutsideYourHomeContainer > ul {
    padding: var(--GW-SPACING-6) 0 0 var(--GW-SPACING-5);
    margin-bottom: 0;
}

.maxWidth {
    width: 60%;
    @include gw-breakpoint-only(phone){
        width: 100%;
    }
    @include gw-breakpoint-only(phoneWide){
        width: 100%;
    }
    @include gw-breakpoint-only(tablet){
        width: 100%;
    }
}