@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);
$pageMaxWidth: 1500px;

.progressBarContainer {
    --nfum-progress-bar-padding: 0rem var(--GW-SPACING-6) 0rem var(--GW-SPACING-6);
    --nfum-progress-bar-header-padding-top: calc(var(--GW-SPACING-5)*3);
    --nfum-progress-bar-header-min-height: 10rem;
    --nfum-progress-icon-size: 20px;

    @include gw-breakpoint-down(phoneWide) {
        --nfum-progress-bar-padding: var(--GW-SPACING-4) var(--GW-SPACING-6) var(--GW-SPACING-6);
        --nfum-progress-bar-header-padding-top: var(--GW-SPACING-8);
        --nfum-progress-bar-header-min-height: 8rem;
    }
    @include gw-breakpoint-only(tablet) {
        --nfum-progress-bar-padding: var(--GW-SPACING-4) 8rem var(--GW-SPACING-6);
    }

    border-top: solid var(--GW-BORDER-WIDTH-THIN) var(--GW-COLOR-TEXT-1);
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: var(--nfum-progress-bar-header-min-height);

    .progressBarHeader {
        padding: var(--nfum-progress-bar-padding);
        width: 100%;
        max-width: $pageMaxWidth;
        display: grid;
        grid-gap: var(--GW-SPACING-5);
        grid-template-columns: auto auto;
        grid-template-areas: "title references";
        align-items: center;

        @include gw-breakpoint-down(phoneWide) {
            grid-template-areas: "title" "references";
        }
    }

    .progressBarTitle {
        grid-area: title;
        font-size: var(--GW-FONT-SIZE-H1);
        font-family: var(--GW-FONT-SERIF);
        max-width: 25rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }

    .policyNumberReference {
        @extend .reference;
        top: -25px;
        position: relative;
    }

    .reference {
        grid-area: references;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        color: var(--NFUM-FONT-COLOR-PRIMARY);

        @include gw-breakpoint-down(phoneWide) {
            align-items: flex-start;
        }
    }
    
    .progressBar {
        display: flex;
        column-gap: var(--GW-SPACING-3);
        padding-top: var(--GW-SPACING-10);
    }
}

.container {
    background-color: var(--GW-COLOR-BACKGROUND-1);
}