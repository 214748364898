.shortMessage {
    color: var(--NFUM-COLOR-TABLE-BORDERS-GREY);
    width: 70%;
}

.referralMessageContainer {
    margin-top: calc(2 * var(--GW-SPACING-10));
}

.maxWidth {
    width: 102%;
}