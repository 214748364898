@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.modalIconContainer {
    display: flex;
    justify-content: flex-start;
    padding-right: 1rem;
}
.modalIcon{
    color: var(--NFUM-COLOR-YELLOW-MAIN);
    font-size: 4rem;
}

.modal {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    max-width: 40%;
    padding: 1rem;
    margin: auto;
    @include gw-breakpoint-only(phone){
        max-width: 85%;
      }
      @include gw-breakpoint-only(phoneWide){
        max-width: 50%;
      }
}

.modalHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;
    h5 {
        font-family: var(--GW-FONT-SERIF);
        font-size: 24px;
        letter-spacing: 0.5px;
        line-height: var(--GW-LINE-HEIGHT);
        text-transform: none;
    }
}


.modalHeaderBorderBottom {
    border-bottom: calc(var(--GW-BORDER-WIDTH)) solid var(--NFUM-COLOR-YELLOW-MAIN);
}

.modalContent {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-10) var(--GW-SPACING-6) 0;
    font-size: 1rem;
}

.modalFooter {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding:1rem 0 1rem 0;
}
.button {
    margin-right:1rem;
}


