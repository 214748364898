@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
        phone: 768px,
        phoneWide: 1024px,
        tablet: 1500px
);

.nfumInputContainer {
    .nfumInput {
        position: relative;
    
        .nfumInputField {
            padding-right: 40px;
            @include gw-breakpoint-only(phone){
                padding-right: 0px;
            }
            @include gw-breakpoint-only(phoneWide){
                    padding-right: 0px;
            }
            @include gw-breakpoint-only(tablet){
                    padding-right: 0px;
            }
            i {
                right: 32%;
            }
        }
    
        .errorIcon {
            color: var(--NFUM-COLOR-ERROR);
        }
    
        .nfumInputIcon {
            position: absolute;
            right: 0px;
            top: 10px;
            @include gw-breakpoint-only(phone){
                right: 30px;
            }
            @include gw-breakpoint-only(phoneWide){
                right: 30px;
            }
            @include gw-breakpoint-only(tablet){
                right: 30px;
            }
        }

        > div:first-child {
            margin-top: var(--GW-SPACING-4);
        }
    
        >div >div:nth-child(2) >div >div:first-child:hover {
            border: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
         
        }
        >div >div:nth-child(2) >div >div:disabled {
            background: var(--NFUM-COLOR-FIELD-DISABLED);
        }  
        >div >div:nth-child(2) >div >div{
            width: 58%;
            @include gw-breakpoint-only(phone){
                width: 100%;
            }
            @include gw-breakpoint-only(phoneWide){
                width: 100%;
            }
            @include gw-breakpoint-only(tablet){
                width: 100%;
            } 
        }
       
    }
    

}