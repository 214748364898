@import 'mixins/icon-mixin.scss';

.nfum-logo-desktop {
    background-image: url('./assets/logos/nfum-logo-desktop.svg');
}

@include generateIcon('circle_agricultural');
@include generateIcon('circle_arrange');
@include generateIcon('circle_attention');
@include generateIcon('circle_business');
@include generateIcon('circle_cat-and-dog');
@include generateIcon('circle_commercial-vehicle');
@include generateIcon('circle_contact');
@include generateIcon('circle_call_back');
@include generateIcon('circle_contents-away-from-home');
@include generateIcon('circle_equine');
@include generateIcon('circle_farming');
@include generateIcon('circle_finance');
@include generateIcon('circle_high-value');
@include generateIcon('circle_home-emergency');
@include generateIcon('circle_home');
@include generateIcon('circle_investments');
@include generateIcon('circle_landlord');
@include generateIcon('circle_legal-expenses');
@include generateIcon('circle_life-cover');
@include generateIcon('circle_motor');
@include generateIcon('circle_travel');
@include generateIcon('circle_agency');
@include generateIcon('circle_accidental-damage');
@include generateIcon('circle_bicycle-extension');
@include generateIcon('circle_building-coverage');
@include generateIcon('circle_content-coverage');
@include generateIcon('circle_excess');
@include generateIcon('circle_tickmark');
@include generateIcon('circle_warning');
@include generateIcon('tooltip');
@include generateIcon('tooltip_selected');
@include generateIcon('building_icon');
@include generateIcon('buildAndCont_icon');
@include generateIcon('contents_icon');
