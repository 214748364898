.modalWrapper {
    display: flex;
    justify-content: center;
}

.modal {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    overflow-y: auto;
    padding: 0;
    max-height: 90vh;
}

.modalHeader {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--GW-SPACING-6) var(--GW-SPACING-10) var(--GW-SPACING-6) var(--GW-SPACING-10);
    position: sticky;
    background: var(--GW-COLOR-BACKGROUND-1);
    top: 0;
    left: 0;
    z-index: 101;

    h5 {
        font-family: var(--GW-FONT-SERIF);
        font-size: var(--GW-FONT-SIZE-LG);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        letter-spacing: 0.5px;
        line-height: var(--GW-LINE-HEIGHT);
        text-transform: none;
    }

    span {
        flex-shrink: 0;
    }
}

.modalTitle {
    margin-right: calc(var(--GW-SPACING-10)*3)
}

.modalContent {
    width: 80%;
    padding: var(--GW-SPACING-4) var(--GW-SPACING-10) var(--GW-SPACING-4) var(--GW-SPACING-10);

    p {
        margin-bottom: var(--GW-SPACING-4);
        font-size: var(--NFUM-FONT-SIZE-16);
    }
}

.modalFooter {
    margin-top: var(--GW-SPACING-4);
    padding: 0 var(--GW-SPACING-10) var(--GW-SPACING-10) var(--GW-SPACING-10);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.modalIcon {
    margin-right: var(--GW-SPACING-4);
    background-image: url('./assets/svg/circle_attention.svg');
    width: 55px;
    height: 55px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: var(--GW-SPACING-10);
}