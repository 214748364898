@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.currentPremiumDetailsContainer {
  background-color: var(--GW-BRAND-COLOR-1);
  padding: var(--GW-SPACING-5) var(--GW-SPACING-5) var(--GW-SPACING-1)
    var(--GW-SPACING-5);
  letter-spacing: 0.5px;
  font-family: var(--GW-FONT-SANS-SERIF);

  span {
    font-size: var(--NFUM-FONT-SIZE-16);
  }

  .currentPremiumInfoContainer {
    font-size: var(--NFUM-FONT-SIZE-16);
  }

  .premiumValue {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
  }

  .yourPremiumText {
    margin-bottom: var(--GW-SPACING-3);
    font-family: var(--GW-FONT-SERIF);
    font-size: var(--NFUM-FONT-SIZE-24);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    @include gw-breakpoint-only(phone){
      font-size: var(--NFUM-FONT-SIZE-20);
    }
  }

  .totalPremiumCostPeriod {
    margin-left: var(--GW-SPACING-2);
  }

  .paymentValueContainer {
    display: flex;
    padding-bottom: var(--GW-SPACING-8);
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
    h4 {
      font-size: calc(var(--GW-SPACING-5) * 2.6);
      line-height: calc(var(--GW-SPACING-6) * 2);
      @include gw-breakpoint-only(phone){
        font-size: calc(var(--GW-SPACING-5) * 2.1);
      }
    }

    span {
      font-size: var(--GW-SPACING-5);
      margin-top: var(--GW-SPACING-5);
    }
  }

  p {
    font-size: calc(var(--GW-SPACING-3) * 2);
    letter-spacing: 0.5px;
    margin-top: var(--GW-SPACING-5);
  }

  .insurancePremiumTax {
    font-size: var(--NFUM-FONT-SIZE-14);
    margin-top: var(--GW-SPACING-3);
    padding-bottom: var(--GW-SPACING-5);
  }
}

.costBreakDownContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: var(--GW-BORDER-WIDTH-THIN) dashed var(--GW-COLOR-TEXT-2);
  margin-top: var(--GW-SPACING-3);
  padding-bottom: var(--GW-SPACING-3);

  .costDifferencesContainer > div {
    font-size: var(--NFUM-FONT-SIZE-16);
    min-width: 80px;
    display: inline-block;
    text-align: right;

    &.newLabel {
      padding-right: var(--GW-SPACING-4);
    }
  }

  .costBreakDown {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
  }
}

.mutualBonus {
  padding-bottom: var(--GW-SPACING-6);
  border-bottom: var(--GW-BORDER-WIDTH-THIN) dashed black;
}

.costBreakDowninnerContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: var(--GW-BORDER-WIDTH-THIN) dashed var(--GW-COLOR-TEXT-2);
  margin-top: var(--GW-SPACING-3);
  padding-bottom: var(--GW-SPACING-3);
  padding-left: 20px;
  padding-right: 20px;

  .costDifferencesContainer > div {
    font-size: var(--NFUM-FONT-SIZE-16);
    min-width: 80px;
    display: inline-block;
    text-align: right;

    &.newLabel {
      padding-right: var(--GW-SPACING-4);
    }
  }

 .costBreakDown {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
  }
}
.mi-arrow{
  background-image: url('../../../../../../applications/quote-and-buy/src/themes/nfumMainTheme/assets/svg/expand.svg');
  background-position: 100px 2px, right;
  background-repeat: no-repeat;
  padding: 0px 22px 0px 17px;
  background-size: 24px;
}
.mi-arrow:hover{
  cursor: pointer;
}
