@import '~@jutro/theme/assets/sass/helpers';

.costInfoTableRow {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    border-bottom: 1px dashed #2D2C2C;
}

.costInfoCoverNameColumn {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
    width: 33%;
    @include gw-breakpoint-only(phone){
        width: 100%;
    }
}

.costInfoAmountColumn {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
    width: 33%;
    text-align: center;
    @include gw-breakpoint-only(phone){
        width: 100%;
        text-align: right;
    }
}