@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.container {
    div:first-child {
        padding-top: 0px;
    }
}

.riskObjectPropertyContainer {
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    padding-bottom: var(--GW-SPACING-4);
    padding-top: var(--GW-SPACING-4);
}

.riskObjectPropertyLabel {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD) !important;
}