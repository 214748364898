.contentAwayFromHomeContainer {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    padding: 0 calc(var(--GW-SPACING) * 5) calc(var(--GW-SPACING) * 3) calc(var(--GW-SPACING) * 5);
    margin-bottom: var(--GW-SPACING-XXL);
    border: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-STROKE);
}

.contentAwayFromHomeHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--GW-SPACING-XXL) 0;
    border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);

    h3 {
        margin: 0 var(--GW-SPACING);
        font-family: var(--GW-FONT-SERIF);
        font-size: var(--GW-FONT-SIZE-MD);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        letter-spacing: 0.5px;
        line-height: var(--GW-LINE-HEIGHT);
        text-transform: none;
    }
}

.contentAwayFromHomeMessage {
    margin-right: var(--GW-SPACING-XXL);
}

.contentAwayFromHomeMessageBox {
    display: flex;
    justify-content: space-between;
    margin: var(--GW-SPACING) 0;
}

.contentAwayFromHomeMessageStrong {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);;
}

.contentAwayFromHomeSwitch {
    [class*="_switch"] {
        flex-direction: column-reverse;
        align-items: flex-start;
        white-space: nowrap;
    }

    [class*="_labelSection"] {
        margin-top: var(--GW-SPACING);
    }
}

.marginTop {
    margin-top: 1rem;
}