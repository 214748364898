@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.addressLookupBottomItems {
  @include gw-breakpoint-up(tablet) {
    display: flex;
    flex-direction: column-reverse;
    gap: inherit;
    align-items: flex-start;
  }  

  @include gw-breakpoint-down(phone) {
    display: flex;
    flex-direction: column-reverse;
    gap: inherit;
    align-items: flex-start;
  } 
}

.postalCodeAndFindAddressContainer {
  display: flex;
  align-items: flex-end;
  width: 70%;
  @include gw-breakpoint-only(phone){
    width: 100%;
  }
  @include gw-breakpoint-only(phoneWide){
    width: 100%;
  }
  @include gw-breakpoint-only(tablet){
    width: 100%;
  }
}

.editAddressButton {
  padding: 0px;
  height: auto;
  margin-top: 1rem;
  font-size: var(--NFUM-FONT-SIZE-16);
}

.backToAddressSearchButton {
  padding: 0px;
  height: auto;
  margin-top: 1rem;
  font-size: var(--NFUM-FONT-SIZE-16);
}

.errorMessage {
  color: var(--GW-COLOR-ERROR);
  margin-top: var(--GW-SPACING-2);
  display: block;
}

.postalCodeSearchContainer {
  flex: 1;
  margin-right: var(--GW-SPACING-6);
  @include gw-breakpoint-only(phone){
    margin-right: var(--GW-SPACING-5);
  }
}

.marginRight {
  margin-right: 5.5rem;
  max-width: 10rem;
  height: 3rem;
  @include gw-breakpoint-only(phone){
    margin-right: 0;
  }
}

.postalCodeSearch {
  width: 100%;
}

.selectedAddressContainer {
  border: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
  border-left: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
  width: 50%;
  padding: 1rem;
  @include gw-breakpoint-only(phone){
   width: 100%;
  }
  @include gw-breakpoint-only(phoneWide){
    width: 100%;
   }
  @include gw-breakpoint-only(tablet){
    width: 100%;
   }
}

.enterAddressManuallyButton {
  margin-top: var(--GW-SPACING-6);
}
