@import '~@jutro/theme/assets/sass/helpers';

.paymentPremiumInfoBoxContainer {
    background-color: var(--GW-BRAND-COLOR-1);
    padding: var(--GW-SPACING-5);
}

.extraTextContainer {
    font-size: var(--GW-FONT-SIZE);
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    padding: var(--GW-SPACING-5);
}

.premiumContainer {
    display: flex;
    padding-bottom: var(--GW-SPACING-3);
    margin-top: var(--GW-SPACING-4);
}

.amount {
    font-size: calc(var(--GW-SPACING-5)*2.4);
    line-height: calc(var(--GW-SPACING-6)*2);
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

p.topText {
    font-size: var(--GW-FONT-SIZE);
    padding-bottom: var(--GW-SPACING-5);
    line-height: 2rem;
}

h2.title {
    font-family: var(--GW-FONT-SERIF);
}

.perTime {
    margin-left: var(--GW-SPACING-3);
    font-size: var(--GW-FONT-SIZE);
    margin-top: 14px;
}

.additionalInfo {
    margin-top: var(--GW-SPACING-5);
    font-size: var(--GW-FONT-SIZE);
    line-height: 2rem;
}

p.premiumTaxInfo {
    margin-top: var(--GW-SPACING-5);
    padding-top: var(--GW-SPACING-3);
    border-top: var(--GW-BORDER-WIDTH-THIN) dashed var(--GW-FIELD-COMPONENT-COLOR);
}