@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.nfumInputContainer {
    .nfumInput {
        position: relative;
    
        .nfumInputField {
            padding-right: var(--GW-SPACING-8);
            @include gw-breakpoint-only(phone){
                padding-right: 0px;
            }
            @include gw-breakpoint-only(phoneWide){
                padding-right: 0px;
            }
            @include gw-breakpoint-only(tablet){
                padding-right: 0px;
            }
            i {
                right: 32%;
            }
        }
    
        .errorIcon {
            color: var(--NFUM-COLOR-ERROR);
        }
    
        .nfumInputIcon {
            position: absolute;
            right: 0px;
            top: 10px;

            @include gw-breakpoint-only(phone){
                right: 30px;
            }
            @include gw-breakpoint-only(phoneWide){
                right: 30px;
            }
            @include gw-breakpoint-only(tablet){
                right: 30px;
            }
        }

        > div:first-child {
            margin-top: var(--GW-SPACING-4);
        }
    
        >div >div:nth-child(2) >div >div >div:hover {
            border: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
        }
        >div >div:nth-child(2) >div >div >div:disabled {
            background: var(--NFUM-COLOR-FIELD-DISABLED);
        }        
    }

}

.nfumDropdownOption {
    border-left: 2px solid var(--NFUM-BORDER-LEFT-COLOR);
    margin-top: 6px;
}

.nfumDropdownOptionSelectIcon {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

.fullSize {
    :global(.jut__InputField__input) {
        border: 1px solid black;
        width: 100% !important;
    }
}