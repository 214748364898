@import "~@jutro/theme/assets/sass/helpers";

button.jut__Button__outlined,
button.jut__Button__outlined[disabled],
button.jut__Button__outlined[disabled]:hover {
    background-color: transparent;
    color: var(--NFUM-COLOR-GREY);
    border: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-YELLOW-MAIN);
    box-shadow: none;
}

button.jut__Button__outlined:hover {
    color: var(--NFUM-COLOR-WHITE);
    background-color: var(--NFUM-COLOR-GREY);
    border-color: var(--NFUM-COLOR-GREY);
    box-shadow: none;

    .jut__Button__icon {
        color: var(--NFUM-COLOR-WHITE);
    }
}

button.jut__Button__outlined:hover:not(.jut__Button__disabled) {
    color: var(--NFUM-COLOR-WHITE);
    background-color: var(--NFUM-COLOR-GREY);
}

button.jut__Button__outlined:focus {
    box-shadow: none;
}

button.jut__Button__outlined:active,
button.jut__Button__outlined:active:focus,
button.jut__Button__outlined:active:focus:hover {
    color: var(--NFUM-COLOR-YELLOW-MAIN);
    background-color: var(--NFUM-COLOR-DARK);
    border-color: var(--NFUM-COLOR-DARK);
    box-shadow: none;

    .jut__Button__icon {
        color: var(--NFUM-COLOR-YELLOW-MAIN);
    }
}

.jut__Button__outlined:active:not(.jut__Button__disabled),
.jut__Button__outlined:active:focus:not(.jut__Button__disabled),
.jut__Button__outlined:active:focus:hover:not(.jut__Button__disabled),
.jut__Button__outlined.jut__Button__activeKeypress:not(.jut__Button__disabled),
.jut__Button__outlined.jut__Button__activeKeypress:focus:not(.jut__Button__disabled) {
    color: var(--NFUM-COLOR-YELLOW-MAIN);
    background-color: var(--NFUM-COLOR-DARK);

    .jut__Button__icon {
        color: var(--NFUM-COLOR-YELLOW-MAIN);
    }
}