@import '~@jutro/theme/assets/sass/helpers';

.nfumRadiobuttonCardIcon {
    max-width: 100px;
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 20px auto;
    @include gw-breakpoint-only(phone){
        margin: 10px auto;
    }
}

.nfumBuildingsRadiobuttonCard {
    @extend .nfumRadiobuttonCardIcon;
    background-image: url('./assets/svg/building_icon.svg');
    @include gw-breakpoint-only(phone) {
        background-position: bottom;
        background-size: 78%
   } 
}

.nfumBuildingsAndContentsRadiobuttonCard {
    @extend .nfumRadiobuttonCardIcon;
    background-image: url('./assets/svg/buildAndCont_icon.svg');
    @include gw-breakpoint-only(phone) {
        background-position: bottom;
        background-size: 78%
   } 
}

.nfumContentsRadiobuttonCard {
    @extend .nfumRadiobuttonCardIcon;
    background-image: url('./assets/svg/contents_icon.svg');
    @include gw-breakpoint-only(phone) {
        background-position: bottom;
        background-size: 78%
   } 
}