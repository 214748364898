@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

$primary-color: #FFCB05;
$text-color: #2D2C2C;
$background-color: white;

.container {
    min-width: 350px;
    background-color: $background-color;
    position: relative;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);

    @include gw-breakpoint-down(tablet) {
        min-width: 345px;
    }
    @include gw-breakpoint-down(phoneWide) {
        min-width: 320px;
    }
}

.headerContainer {
    background-color: $primary-color;
}

h3.headerTextPaddingLeft {
    padding-left: 72px !important;
}

h3.headerText {
    padding: 24px;
    width: 100%;
    background-color: $primary-color;
    font: 400 18px / 30px "Archer SSm", serif;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: none;
    color: $text-color;
    text-align: left;
}

img.headerImage {
    position: absolute;
    left: 0;
    height: 107px;
    width: 55px;
}

.text {
    padding: 24px;
    text-align: left;
    font: 400 14px / 24px "Meta Pro", sans-serif;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: none;
    color: $text-color;
}

.hidden {
    display: none;
}