
.riskObjectBox {
    margin-bottom: var(--GW-SPACING-6);;
}

.riskObjectTitle {
    background: var(--NFUM-COLOR-FIELD-DISABLED);
    padding: var(--GW-SPACING-5) var(--GW-SPACING-4);
    border-top: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY);
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 700;
    margin: 0;
}

.riskObjectPropertyLabel {
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 400;
}
.riskObjectProperty {
    padding-left: var(--GW-SPACING-4);
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 400;

    &:global(.jut__FieldComponent__fieldComponent) {
        border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
        padding-bottom: var(--GW-SPACING-4);
        padding-top: var(--GW-SPACING-4);
        margin-top: 0;
    }
}

.riskObjectSubtitle {
    padding-left: var(--GW-SPACING-4);
    font-size: var(--NFUM-FONT-SIZE-16);
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    padding-bottom: var(--GW-SPACING-4);
    padding-top: var(--GW-SPACING-4);
    margin: 0;
}

.riskLocationTitle {
    background: var(--NFUM-COLOR-LIGHT-GREY);
    padding: var(--GW-SPACING-5) var(--GW-SPACING-4);
    border-bottom: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY);
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 700;
    margin: 0;
}

.widthThirty {
    width: 30rem;
}

.paddingRight10 {
    padding-right: 10rem;
}
.paddingRight8 {
    padding-right: 8rem;
}

.boldFonts {
    font-weight: bold;
    color: var(--GW-FIELD-COMPONENT-COLOR) !important;
}

.riskObjectSubtitleYellow {
    padding-left: var(--GW-SPACING-4);
    font-size: var(--NFUM-FONT-SIZE-16);
    border-top: 1px solid var(--NFUM-COLOR-YELLOW-MAIN);
    border-bottom: 1px solid var(--NFUM-COLOR-YELLOW-MAIN);
    padding-bottom: var(--GW-SPACING-4);
    padding-top: var(--GW-SPACING-4);
    margin: 0;
}

.unspecifiedFineArtListsHeader{
    font-weight: bold !important;
    background-color: var(--NFUM-COLOR-LIGHT-GREY) ;
    padding: var(--GW-SPACING-4);
}

.unspecifiedFineArtListsHeaderLabel{
    font-weight: bold;
    font-size: inherit;
    color: black !important;
}

.fineArtsMessageContainer {
    background-color: var(--GW-COLOR-BACKGROUND-4);
    padding: var(--GW-SPACING-4);
}

.fineArtMsgContent1 {
    font-size: 1rem;
    padding-bottom: 5px;
    padding-top: 1rem;
}

.fineArtMsgHeader{
    font-size:1rem;
    font-weight: 600;
}
.fineArtsMessageContent112, .fineArtsMessageContent212{
    text-decoration: underline ;
    font-weight: 600;
}
.fineArtsMessageContent112:hover, .fineArtsMessageContent212:hover{
    cursor: pointer;
}
.fineArtsMessageContent212{
    color: var(--NFUM-COLOR-DARK);
}

.messageAgentCls {
    border:var(--GW-BORDER-WIDTH-THIN) solid var(--GW-BRAND-COLOR-2);
    font-size: var(--NFUM-FONT-SIZE-16);
    padding: var(--GW-SPACING-2);
}

.tableHeader table>thead>tr>th{
    background-color:  var(--NFUM-COLOR-LIGHT-GREY) !important;
}
