$box-dashed-border-color: #ABABAB;
$box-background-color: white;

.backgroundContainer {
    background: $box-background-color url("assets/loading-background.png") no-repeat fixed center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoContainer {
    width: 600px;
    background: $box-background-color;
    padding: 3rem;
    text-align: center;

    h2 {
        font-family: var(--GW-FONT-SERIF);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        font-size: var(--GW-FONT-SIZE-LG);
    }
}

.marketingInfoBox {
    border: 1px dashed $box-dashed-border-color;
}

.infoBox {
    border: 1px dashed $box-dashed-border-color;
    padding: 1.75rem;
}