@import "~@jutro/theme/assets/sass/helpers";

$nfum-letter-spacing: 0.5px;
$nfum-text-size: var(--NFUM-FONT-SIZE-16);
$nfum-icon-font-size: var(--NFUM-FONT-SIZE-24);

.jut__Button__button {
    > span {
        letter-spacing: $nfum-letter-spacing;
        font-size: $nfum-text-size;
        display: flex;
        justify-content: center;
        align-items: center;

        > span {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .jut__Button__icon {
        font-size: $nfum-icon-font-size;
    }
}