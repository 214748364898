@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.questionsSetContainer {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    margin-bottom: 1.25rem;
}

.accordionContent {
    padding: 24px 72px;
    @include gw-breakpoint-down(phone) {
        padding: var(--GW-SPACING-5) var(--GW-SPACING-5);
    }
    > ul {
        padding: var(--GW-SPACING-10) var(--GW-SPACING-10) 0 calc(var(--GW-SPACING-10)*0.5);
        li {
            font-family: var(--GW-FONT-FAMILY);
            font-weight: var(--GW-FONT-WEIGHT-REGULAR);
            font-size: var(--GW-FONT-SIZE);
            line-height: var(--GW-LINE-HEIGHT);
            padding: 0;
            letter-spacing: 0.5px;
        }

        hr {
            margin: var(--GW-SPACING-6) calc(-1 * var(--GW-SPACING-6));
            border-top: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);
        }
        @include gw-breakpoint-only(phone){
            padding-right: var(--GW-SPACING-0);
        }
    }
    h3{
        font-size: var(--GW-FONT-SIZE);  
    } 
}

li span,
i {
    color: var(--NFUM-COLOR-GREY);
}

.tooltipIcon {
    margin-left: var(--GW-SPACING-4);
    top: 5px;
    position: relative;
}

.subHeading {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
    padding: var(--GW-SPACING-5) 0 0 0;
    font-size: var(--GW-FONT-SIZE);
}

.link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
    font-size: var(--GW-FONT-SIZE);
}

.buttonsInfoContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--GW-SPACING-10);
}

.byClickingContinueContainer {
    background: white;
    padding: var(--GW-SPACING-5) calc(var(--GW-SPACING-5)*1.5);
    line-height: 1.5rem;
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-YELLOW-MAIN);
    font-size: var(--GW-FONT-SIZE);
}

.continueText {
    margin: 0px 4px;
}