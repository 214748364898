@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
        phone: 768px,
        phoneWide: 1024px,
        tablet: 1500px
);

.propertyStyling {
        padding-right: 40px;
        @include gw-breakpoint-only(phone){
                padding-right: 0px;
        }
        @include gw-breakpoint-only(phoneWide){
                padding-right: 0px;
        }
        @include gw-breakpoint-only(tablet){
                padding-right: 0px;
        }
}

.radioButton > div > div > div[role=radiogroup] > div, .propertyStyling > div > div[role=radiogroup] > div{
        display: flex;
        align-items: center;
        box-shadow: var(--GW-SHADOW-2);
        padding: var(--GW-SPACING-4) var(--GW-SPACING-5);
        margin-bottom: var(--GW-SPACING-5);
}

.yesNoRadioButton {
        padding-right: 40px;
        @include gw-breakpoint-only(phone){
                padding-right: 0px;
        }
        @include gw-breakpoint-only(phoneWide){
                padding-right: 0px;
        }
        @include gw-breakpoint-only(tablet){
                padding-right: 0px;
        }
}


.yesNoRadioButton > div > div > div[role=radiogroup] {
        display: flex;
        gap: var(--GW-SPACING-5);
        > div {
                display: flex;
                align-items: center;
                box-shadow: var(--GW-SHADOW-2);
                padding: var(--GW-SPACING-4) var(--GW-SPACING-5);
                margin-bottom: var(--GW-SPACING-5);
                width: -webkit-fill-available;
                @include gw-breakpoint-only(phone){
                        padding: var(--GW-SPACING-4) var(--GW-SPACING-4);
                }
        }
}