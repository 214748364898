@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.topBlankLine {
    margin-top: 1rem !important;
}

.link {
    text-decoration: underline;
    cursor: pointer;
    color: unset;
}