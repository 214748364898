.buttonContainer {
    --gw-wizard-sidebar-button-container-width: 60%;
    margin: var(--GW-SPACING-3) 0;
    display: flex;
    flex-direction: column;
    width: var(--gw-wizard-sidebar-button-container-width);
}

.actionButtons {
    margin-bottom: var(--GW-SPACING-3);
}

.actionButtons span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}