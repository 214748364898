.xDays {
    margin-left: 4px;
    margin-right: 4px;
    font-weight: 600;
}

.horizontalRadioButtons {
    width: 60%;
    margin-top: 0px;
    .jut__RadioButton__radioButton {
        display: inline;
        margin-right: 15px;
    }
}

.sectionsContainer {
    .section:not(:first-child) {
        margin-top: var(--GW-SPACING-8);
    }
}