@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.nfumSwitchContainer {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    flex-direction: column;

    .nfumSwitchInnerContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        @include gw-breakpoint-only(phone){
            width: 100%;
        }

        :global(.jut__FieldComponent__fieldComponent) {
            margin-top: 0;
            line-height: 20px;
        }
    }

    .switchDescription {
        color: #2D2C2C;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Meta Pro, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.5px;
    }

    .switchExtraDescription {
        color: #2D2C2C;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Meta Pro, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
    }

    .label {
        color: #2D2C2C;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Meta Pro, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
    }

    .labelHidden {
        visibility: hidden;
    }
}