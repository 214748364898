@import "~@jutro/theme/assets/sass/helpers";

.link {
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
}

.bold {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}
