@import '~@jutro/theme/assets/sass/helpers';

.valuablesCtaButtonWrapper {
  margin-left: 35rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.valuablesAdditionalDescriptionWrapper {
  font-size: 1rem;
  padding-left: var(--GW-SPACING-4);
  font-weight: bold;
  @include gw-breakpoint-only(phone){
    padding-left: var(--GW-SPACING-0);
  }
}

.paddingTop {
  font-size: 15px;
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-weight: bold;
}

.unSpecifiedCls {
  margin-top: 1.5rem;
}

.accordionContainer {
  background-color: var(--GW-COLOR-BACKGROUND-1);
  border: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-STROKE);
  border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);
  padding: 0;
  margin-bottom: 0;
  &:global(.isOpen) {
    .accordionCardHeader {
      background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    }
  }
}
  
.accordionCardHeader {
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  font-family: var(--GW-FONT-SERIF);
  letter-spacing: 0.5px;
  border-bottom: var(--GW-SPACING-1) solid #a9a9a9;
  font-size: calc(var(--GW-SPACING-3) * 2.5);
  padding: var(--GW-SPACING-6) 0;
  padding-left: 0px;
}
  
.accordionCardBody {
  margin: var(--GW-SPACING-5);
  margin-bottom: var(--GW-SPACING-10);
  padding-left: 65px;
}
  
.accordionCardTitleContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex: auto;
  div > h3 {
    font-family: var(--GW-FONT-SERIF);
  }
  padding-left: var(--GW-SPACING-5);
  padding-right: var(--GW-SPACING-5);
}

.riskObjectProp {
  background-color: #f2f2f2;
  padding-left: 1rem;
  font-size: medium;
  height: 3.5rem;
}

.typeCls {
  width: 50%;
  display: inline-block;
  @include gw-breakpoint-only(phone){
    font-size: var(--GW-FONT-SIZE-H5);
  }
}

.valueableReplacementCost{
  width: 50%;
  display: inline-block;
  @include gw-breakpoint-only(phone){
    font-size: var(--GW-FONT-SIZE-H5);
    text-align: right;
  }
}

.insuredValuable{
  width: 60%;
  display: inline-block;
  @include gw-breakpoint-only(phone){
    font-size: var(--GW-FONT-SIZE-H5);
    text-align: right;
  }
}

.riskObjectTitle {
  font-size: 1rem;
  margin-left: -1.5rem;
}

.riskObjectProperty {
  padding-left: var(--GW-SPACING-4);
  font-size: 1rem;
  font-weight: 400;
  @include gw-breakpoint-only(phone){
    padding-left: var(--GW-SPACING-0);
  }
  
  &:global(.jut__FieldComponent__fieldComponent) {
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    padding-bottom: var(--GW-SPACING-4);
    padding-top: var(--GW-SPACING-4);
    margin-top: 0;
  }
  :global(.jut__DatePickerField__readOnly) {
    font-weight: 400;
    padding: var(--GW-SPACING-3) var(--GW-SPACING-6);
    font-size: var(--GW-FONT-SIZE);
    padding-left: 12px;
  }
}

.valuablesCtaButtonWrapper1 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.descriptionAndButtonContainer {
  display: flex;
  justify-content: space-between;
  font-size: var(--GW-FONT-SIZE);
}

.valuablesAdditionalDescriptionWrapper1 {
  font-size: 1rem;
  padding-bottom: 5px;
  max-width: 34rem;
}

.riskObjectProps {
  background: var(--NFUM-COLOR-LIGHT-GREY);
  padding: var(--GW-SPACING-5);
  font-size: medium;
  border-bottom: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY);
  margin-top: 1rem;
}

.link {
  text-decoration: underline;
}

.valuablesWarningCls {
  background-color: var(--GW-COLOR-BACKGROUND-4);
  padding-left: 1rem;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  padding-top: 7px;
  padding-bottom: 7px;
}

.bespokeValuablesCtaDescriptionAndButtonWrapper {
  font-size: 1rem;
  padding-bottom: 5px;
  padding-top: 1rem;
}

.valuablesAdditionalDescriptionWrapper2 {
  background-color: var(--GW-COLOR-BACKGROUND-4);
  padding-left: 2rem;
  padding-bottom: 1rem;
}

.valuablesAdditionalDescWrapper {
  width: 30rem;
  @include gw-breakpoint-only(phone){
    width: 100%;
  }
}

.fontSize20 {
  font-size: 20px;
}

.agentCls {
  border: solid;
  padding-left: 1.5rem;
  border-width: var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH);
  border-color: var(--GW-BRAND-COLOR-2) var(--GW-BRAND-COLOR-2) var(--GW-BRAND-COLOR-2) var(--GW-COLOR-BRAND-1);
  padding-bottom: 1rem;
  font-size: 15px;
  max-width: 40rem;
}

.bold {
  font-weight: bold;
}

.editButton {
  margin-left: -4rem;
}

.deleteButton {
  margin-left: 2rem;
}
.unspecifiedValuablesListsHeader {
  font-weight: bold !important;
  font-size: inherit;
  background-color: var(--NFUM-COLOR-LIGHT-GREY) ;
  padding-top: var(--GW-SPACING-2);
  padding-left: var(--GW-SPACING-4);
  padding-bottom: var(--GW-SPACING-2) ;
  display: flex;
}
.unspecifiedValubalesListsHeaderLabel{
  color: black !important;
    font-weight: bold;
}
.unSpecifiedValuableItemsContainer {
  margin-top: 1rem;
}