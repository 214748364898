@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.container {
    .label {
        color: var(--NFUM-FONT-COLOR-PRIMARY);
        font-size: var(--NFUM-FONT-SIZE-16);
        font-weight: 400;
    }

    .value {
        font-size: var(--NFUM-FONT-SIZE-16);
        font-weight: 400;
        margin-bottom: 0px;
    }

    &.horizontal {
        display: flex;

        .label {
            flex: 1;
            display: flex;
            align-items: center;
        }

        .value {
            flex: 2;
            display: flex;
            align-items: center;
        }

        @include gw-breakpoint-down(phone){
            flex-direction: column;
        }
    }
}
