@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);
$pageMaxWidth: 1500px;

.digitalContentContainer {
    --gw-wizard-page-main-margin: var(--GW-SPACING-10) 12rem var(--GW-SPACING-10) 12rem;
    --gw-wizard-page-main-phone-margin: var(--GW-SPACING-10) var(--GW-LAYOUT-6) var(--GW-SPACING-10);
    --gw-wizard-page-main-tablet-margin: var(--GW-SPACING-10) var(--GW-LAYOUT-6) var(--GW-SPACING-10);
    --gw-wizard-page-main-flex-direction: row;

    @include gw-breakpoint-down(phoneWide) {
        --gw-wizard-page-main-margin: var(--gw-wizard-page-main-tablet-margin);
        --gw-wizard-page-main-flex-direction: column;
    }
    @include gw-breakpoint-down(phone) {
        --gw-wizard-page-main-margin: var(--gw-wizard-page-main-phone-margin);
        --gw-wizard-page-main-flex-direction: column;
    }
    @include gw-breakpoint-only(tablet) {
        --gw-wizard-page-main-margin: var(--GW-SPACING-10) 8rem var(--GW-SPACING-10) 8rem;
    }

    flex-direction: var(--gw-wizard-page-main-flex-direction);
    max-width: $pageMaxWidth;
    width: 100%;
    align-items: flex-start;
    display: flex;
    padding: var(--gw-wizard-page-main-margin);
}

.wizardMain {
    display: flex;
    width: 100%;
    justify-content: center;
}

.wizardMainShowSidebar {
    --gw-wizard-page-main-areas: var(--gw-wizard-page-main-areas-original);
}

.wizardNavigation {
    max-width: 25%;
    flex-basis: 25%;
    min-width: 230px;
    margin-left: 5%;
    position: sticky;
    top: 120px;

    @include gw-breakpoint-down(phoneWide) {
        max-width: 100%;
        flex-basis: 100%;
        margin-left: 0;
    }
}

.wizardContent {
    max-width: 70%;
    flex-basis: 70%;
    grid-area: wizcontent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include gw-breakpoint-down(phoneWide) {
        max-width: 100%;
        flex-basis: 100%;
        margin-left: 0;
    }
}

.wizardWidgets {
    margin-top: var(--GW-SPACING-6);
}

.navButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include gw-breakpoint-down(phone) {
        position: relative;
        margin-bottom: var(--GW-SPACING-8);
    }
.cancelTransactionButton{
     margin-top: var(--GW-SPACING-5);
     @include gw-breakpoint-only(phone) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-top: var(--GW-SPACING-8);
     }
    
    }

    .navTransitionButtons {
        display: flex;
        justify-content: flex-end;
        margin-bottom: var(--GW-SPACING-8);
        margin-top: var(--GW-SPACING-5);
        width: 100%;

        .nextButton {
            grid-area: next;
            margin-left: var(--GW-SPACING-4);
        }
        @include gw-breakpoint-only(phone) {
            text-align: center;
            display: inline-block;
        }
    }

    .emailButton,
    .cancelButton,
    .saveForLaterButton {
        margin-top: var(--GW-SPACING-10);
        margin-right: var(--GW-SPACING-6);
    }

    .navTransitionButtonOnlyNext {
        grid-template-columns: auto;
        grid-template-areas: "next";
    }
}

.cancelButton,
.jumpBackButton {
    margin-top: var(--GW-SPACING-6);
}
