@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);
$pageMaxWidth: 1500px;

.titleContainer {
    min-height: auto;
    width: 100%;
    background: var(--GW-COLOR-BACKGROUND-1);
}

.uwIssueReferralWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.uwIssueReferralContainer {
    --nfum-footer-info-padding: var(--GW-SPACING-10) 12rem var(--GW-SPACING-10) 12rem;

    @include gw-breakpoint-down(phone) {
        flex-direction: column;
        --nfum-footer-info-padding: var(--GW-SPACING-4) var(--GW-SPACING-6) var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(phoneWide) {
        // flex-direction: column;
        --nfum-footer-info-padding: var(--GW-SPACING-10) var(--GW-SPACING-6) var(--GW-SPACING-10) var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(tablet) {
        --nfum-footer-info-padding: var(--GW-SPACING-10) 8rem var(--GW-SPACING-10) 8rem;
    }

    width: 100%;
    max-width: $pageMaxWidth;
    padding: var(--nfum-footer-info-padding);
    display: flex;
    column-gap: var(--GW-SPACING-6);
}

.rightColumn {
    flex: 1
}

.leftColumn {
    flex: 3;
}

.progressBarContainer {
    --nfum-progress-bar-padding: 0rem var(--GW-SPACING-6) 0rem 12rem;
    --nfum-progress-bar-header-padding-top: calc(var(--GW-SPACING-0)*3);
    --nfum-progress-bar-header-min-height: 10rem;
    --nfum-progress-bar-header-min-height-none: 0rem;
    --nfum-progress-icon-size: 20px;

    @include gw-breakpoint-down(phoneWide) {
        --nfum-progress-bar-padding: var(--GW-SPACING-4) var(--GW-SPACING-6) var(--GW-SPACING-6);
        --nfum-progress-bar-header-padding-top: var(--GW-SPACING-8);
        --nfum-progress-bar-header-min-height: 8rem;
        min-height: var( --nfum-progress-bar-header-min-height-none);
    }
    @include gw-breakpoint-only(tablet) {
        --nfum-progress-bar-padding: var(--GW-SPACING-4) 8rem var(--GW-SPACING-6);
        min-height: var( --nfum-progress-bar-header-min-height-none);
    }

    width: 100%;
    display: flex;
    justify-content: center;
    min-height: var(--nfum-progress-bar-header-min-height);

    .progressBarHeader {
        border-top: solid var(--GW-BORDER-WIDTH-THIN) var(--GW-COLOR-TEXT-1);
        padding: var(--nfum-progress-bar-padding);
        padding-top: var(--nfum-progress-bar-header-padding-top);
        width: 100%;
        max-width: $pageMaxWidth;
        display: grid;
        grid-gap: var(--GW-SPACING-5);
        grid-template-columns: auto auto;
        grid-template-areas: "title references";
        align-items: center;

        @include gw-breakpoint-down(phoneWide) {
            grid-template-areas: "title" "references";
        }
        @include gw-breakpoint-down(tablet) {
            padding-top: var(--GW-SPACING-5);
            display:block;
        }
    }

    .progressBarTitle {
        grid-area: title;
        h2{
            font-weight: var(--GW-FONT-WEIGHT-THIN);
            font-size: var(--GW-FONT-SIZE-H1);
            font-family: var(--GW-FONT-SERIF);
        }
        @include gw-breakpoint-only(phone){
            font-size: var(--GW-FONT-SIZE-H2);
        }
    }

    .reference {
        grid-area: references;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        color: var(--NFUM-FONT-COLOR-SECONDARY);

        @include gw-breakpoint-down(phoneWide) {
            align-items: flex-start;
        }
    }
    
    .progressBar {
        display: flex;
        column-gap: var(--GW-SPACING-3);
        padding-top: var(--GW-SPACING-10);
    }
}
