@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.nfumInputContainer {
    padding: 0;
    margin: 0;
    border: none;
    .nfumInput {
        position: relative;
    
        .nfumInputField {
            i {
                z-index: 1;
            }
        }
    
        .errorIcon {
            color: var(--NFUM-COLOR-ERROR);
        }
    
        .nfumInputIcon {
            position: absolute;
            left: 13rem;
            top: 10px;
        }

        > div:first-child {
            margin-top: var(--GW-SPACING-4);
        }
    
        input:hover {
            border: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
        }
        input:disabled {
            background: var(--NFUM-COLOR-FIELD-DISABLED);
        }        
    }

    .errored {
        input {
            border-color: var(--NFUM-COLOR-ERROR);
        }
    }
    legend{
        color: var(--NFUM-FONT-COLOR-PRIMARY);
        padding-top: var(--GW-SPACING-5);
     }
}

