.nfumCheckboxContainer {

    display: flex;

    a {
        color: unset;
    }

    .tooltipIcon {
        font-size: var(--GW-ICON-FONT-SIZE-3);
        line-height: var(--GW-ICON-LINE-HEIGHT);
        color: var(--NFUM-FONT-COLOR-PRIMARY);
        background-color: transparent;
        border: none;
        > i {
            position: relative;
            width: auto;
        }
        > i:focus {
            border-radius: 50%;
            outline: none;
            box-shadow: 0 0 0 2px var(--NFUM-COLOR-YELLOW-MAIN);
        }
        .tooltipVisible {
            border-radius: 50%;
            background-color: var(--NFUM-COLOR-YELLOW-MAIN);
        }
    }
    
}

.tooltipContainer {
    border: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    border-left: 2px solid var(--NFUM-BORDER-LEFT-COLOR);
    max-width: 70%;
    width: fit-content;

    .tooltipText {
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.tooltipHidden {
    display: none;
}

.nfumCheckbox {
    width: 70%;
    box-shadow: var(--GW-SHADOW-2);
    padding: var(--GW-SPACING-8) var(--GW-SPACING-5);
    margin-bottom: var(--GW-SPACING-5);
}

.nfumCheckboxSelected {
    border: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
}