@import "~@jutro/theme/assets/sass/helpers";
.onlineSupportButton {
    position: fixed;
    bottom: 2%;
    right: 5%;
    border-radius: 100%;
    background-color : var(--GW-BRAND-COLOR-1);
    width:var(--GW-LAYOUT-10);
    height:var(--GW-LAYOUT-10);
    z-index: var(--GW-Z-INDEX-1);
    .helpIcon {
        font-size: var(--GW-FONT-SIZE);
    }
}

div.popupArrow {
    min-width: 400px !important;
}

.popupArrow {
    bottom: 12%;
    right: 6%;

    @include gw-breakpoint-down(phone) {
        max-width: none;
    }
    @include gw-breakpoint-only(tablet) {
        max-width: 25vw;
    }
    &::after {
        content: "";
        position: absolute;
        top: 100%;
        right: 1.5%;
        border-width: var(--GW-SPACING-5);
        border-style: solid;
        border-color: var(--GW-COLOR-BACKGROUND-1) transparent transparent transparent;
    }
}

.onlineSupportModal.modal {
        background-color: var(--GW-COLOR-BACKGROUND-1);
        overflow-y: auto;
        max-height: 32rem;
        position: absolute;
        right: 0%;
        bottom: 0%;
        
        @include gw-breakpoint-down(phone) {
            max-width: 50vw;
        }
        h3 {
            font-family: var(--GW-FONT-SERIF);
            font-size: var(--NFUM-FONT-SIZE-18);
            font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        }
        h4 {
            font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        }
        a {
            color: unset;
        }
        .faqlinks {
            margin: 1px;
            text-decoration: underline;
            font-size: calc(var(--GW-FONT-SIZE-SM)/1.05);
        }
        .modalConvenientSupport{
            font-size: calc(var(--GW-FONT-SIZE-SM)/1.05);
        }
    
        .modalHeader {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: var(--GW-SPACING-5);
            position: sticky;
            background: var(--GW-COLOR-BACKGROUND-1);
            top: 0;
            left: 0;
            z-index: 2;
            border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
    
            .modalTitle {
                margin: var(--GW-SPACING-2);
                flex: 1;
            }
            .modalHeaderClose {
                cursor: pointer;
            }
        }
        .modalContent {
            padding: var(--GW-SPACING-4);
            .modalContentRow {
                padding: var(--GW-SPACING-4) 0;
                &:not(:last-child) {
                    border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);    
                }
                h4,p {
                    margin-bottom: var(--GW-SPACING-4);
                    margin-top: var(--GW-SPACING-4);
                }
            }
            .faqSection {
                .linkButton {
                    margin-top: var(--GW-SPACING-4);
                }
            }
            .oSModalCallNumber {
                cursor:pointer;
                font-size: var(--NFUM-FONT-SIZE-18);

            }
            .callTimingsDetails {
                margin-bottom: 0;
                padding-left: var(--GW-SPACING-5);
                font-size: calc(var(--GW-FONT-SIZE-SM)/1.05);
            }
            .linkButton {
                align-items: flex-end;
                background-color: transparent;
                box-shadow: none;
                &:hover,
                &:active,
                &:focus {
                    background-color: transparent;
                    text-decoration: none;
                }
    
                & > span:first-child {
                    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
                    padding-bottom: var(--GW-SPACING-2);
                    display: flex;
                    &:hover,
                    &:active,
                    &:focus {
                        background-color: transparent;
                        text-decoration: none;
                    }
                    & > span:first-child {
                        display: inline-flex;
                        &:hover,
                        &:active,
                        &:focus {
                            background-color: transparent;
                            text-decoration: none;
                        }
                        i {
                            margin-left: var(--GW-SPACING-3);
                        }
                    }
                }
            }
        }
    }