.valuablesCtaButtonWrapper {
  margin-left: 35rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.valuablesAdditionalDescriptionWrapper {
  padding-bottom: 1rem;
  font-size: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  font-weight: bold;
}

.paddingTop {
  padding-top: 1rem;
  font-size: 15px;
  padding-bottom: 1rem;
  font-weight: bold;
}

.unSpecifiedCls {
  margin-top: 1.5rem;
}

.typeCls {
  padding-right: 12rem;
}

.riskObjectTitle {
  font-size: 1rem;
  margin-left: -1.5rem;
}

.riskObjectProperty {
  padding-left: var(--GW-SPACING-4);
  font-size: 1rem;
  font-weight: 400;
  
  &:global(.jut__FieldComponent__fieldComponent) {
    border-bottom: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    padding-bottom: var(--GW-SPACING-4);
    padding-top: var(--GW-SPACING-4);
    margin-top: 0;
    max-width: 44rem;
  }
  :global(.jut__DatePickerField__readOnly) {
    font-weight: 400;
    padding: var(--GW-SPACING-3) var(--GW-SPACING-6);
    font-size: var(--GW-FONT-SIZE);
    padding-left: 12px;
  }
}

.valuablesCtaButtonWrapper1 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.descriptionAndButtonContainer {
  display: flex;
  justify-content: space-between;
  font-size: var(--GW-FONT-SIZE);
}

.valuablesAdditionalDescriptionWrapper1 {
  padding-bottom: 1rem;
  font-size: 1rem;
  max-width: 34rem;
}

.riskObjectProps {
  background: var(--NFUM-COLOR-LIGHT-GREY);;
  padding-left: 1rem;
  font-size: medium;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY);
  margin-top: 1rem;
}

.link {
  text-decoration: underline;
}

.valuablesWarningCls {
  background-color: var(--GW-COLOR-BACKGROUND-4);
  padding-left: 1rem;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  padding-top: 7px;
  padding-bottom: 7px;
}

.bespokeValuablesCtaDescriptionAndButtonWrapper {
  padding-bottom: 1rem;
  font-size: 1rem;
  padding-top: 1rem;
}

.valuablesAdditionalDescriptionWrapper2 {
  background-color: var(--GW-COLOR-BACKGROUND-4);
  padding-left: 2rem;
  padding-bottom: 1rem;
}

.valuablesAdditionalDescWrapper {
  width: 42rem;
}

.riskObjectProp {
  background-color: #f2f2f2;
  padding-left: 1rem;
  font-size: medium;
  height: auto;
  word-wrap: break-word;
}

.fontSize20 {
  font-size: 20px;
}

.agentCls {
  border: solid;
  padding-left: 1.5rem;
  border-width: var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH);
  border-color: var(--GW-BRAND-COLOR-2) var(--GW-BRAND-COLOR-2) var(--GW-BRAND-COLOR-2) var(--GW-COLOR-BRAND-1);
  padding-bottom: 1rem;
  font-size: 15px;
  max-width: 40rem;
}

.bold {
  font-weight: bold;
}