@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

$primary-color: #FFCB05;
$header-text-color: white;
$header-bg-color: #2D2C2C;
$text-color: #2D2C2C;
$background-color: white;

.container {
    min-width: 350px;
    background-color: $background-color;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);

    @include gw-breakpoint-down(tablet) {
        min-width: 345px;
    }
    @include gw-breakpoint-down(phoneWide) {
        min-width: 320px;
    }
}

.imageContainer {
    text-align: left;
    width: 35%;
    margin-top: 24px;
    padding-left: 24px;
}

p.text {
    padding: 24px;
    text-align: left;
    font: 400 14px / 24px "Meta Pro", sans-serif;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: none;
    color: $text-color;
}

.buttonContainer {
    text-align: left;
}

.button {
    margin-top: 24px;
    text-align: left;
}

.hidden {
    display: none;
}

h3.headerText {
    padding: 24px;
    width: 100%;
    background-color: $header-bg-color;
    font: 400 18px / 30px "Archer SSm", serif;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: none;
    color: $header-text-color;
    text-align: left;
}