.interactive_active-eye:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f101";
}

.interactive_active-save:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f102";
}

.interactive_default-eye:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f103";
}

.interactive_default-save:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f104";
}

.interactive_information-active:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f105";
}

.interactive_information-default:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f106";
}

.prefix_dollar:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f107";
}

.prefix_number:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f108";
}

.prefix_pound:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f109";
}

.roundel-standard_grey-white:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f10a";
}

.social_facebook:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f10e";
}

.social_linkedin:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f10f";
}

.social_twitter:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f110";
}

.standard_account:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f111";
}

.standard_burger:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f112";
}

.standard_calendar:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f113";
}

.standard_chevron-left:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f114";
}

.standard_chevron-right:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f115";
}

.standard_claim:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f116";
}

.standard_collapse:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f117";
}

.standard_cross:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f118";
}

.standard_download:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f119";
}

.standard_edit:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f11a";
}

.standard_email:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f11b";
}

.standard_expand:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f11c";
}

.standard_external:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f11d";
}

.standard_help:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f11e";
}

.standard_location:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f11f";
}

.standard_lock:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f120";
}

.standard_minus:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f121";
}

.standard_notification:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f122";
}

.standard_phone:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f123";
}

.standard_plus:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f124";
}

.standard_print:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f125";
}

.standard_search:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f127";
}

.standard_sign-out:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f128";
}

.standard_sort:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f129";
}

.standard_tick:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f12a";
}

.validation_error:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f12b";
}

.validation_success:before {
    font-family: "NFU Icons", sans-serif;
    content: "\f12c";
}
