@import "~@jutro/theme/assets/sass/helpers";

$text-button-size: 30px;

button.jut__Button__text,
button.jut__Button__text[disabled],
button.jut__Button__text[disabled]:hover {
    height: $text-button-size;
    padding: 0;
    background-color: transparent;
    color: var(--NFUM-COLOR-GREY);
    box-shadow: none;

    > span {
        transition-duration: 150ms;
        transition-property: background-color, color, border-color, opacity;
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-YELLOW-MAIN);
        padding-bottom: 8px;
    }
}

button.jut__Button__text:hover {
    height: $text-button-size;
    padding: 0;
    background-color: transparent;
    color: var(--NFUM-COLOR-GREY);
    box-shadow: none;

    > span {
        transition-duration: 150ms;
        transition-property: background-color, color, border-color, opacity;
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-GREY);
        padding-bottom: 8px;
    }
}

button.jut__Button__text:focus {
    box-shadow: none;
}

button.jut__Button__text:active,
button.jut__Button__text:active:focus,
button.jut__Button__text:active:focus:hover {
    height: $text-button-size;
    padding: 0;
    background-color: transparent;
    color: var(--NFUM-COLOR-GREY);
    box-shadow: none;

    > span {
        transition-duration: 150ms;
        transition-property: background-color, color, border-color, opacity;
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-GREY);
        padding-bottom: 8px;
    }
}

button.jut__Button__text:hover:not(.jut__Button__disabled) {
    background-color: transparent;
}

button.jut__Button__text:active:not(.jut__Button__disabled),
button.jut__Button__text:active:focus:not(.jut__Button__disabled),
button.jut__Button__text:active:focus:hover:not(.jut__Button__disabled),
button.jut__Button__text.jut__Button__activeKeypress:not(.jut__Button__disabled),
button.jut__Button__text.jut__Button__activeKeypress:focus:not(.jut__Button__disabled) {
    background-color: transparent;
}