.section {
    border: none;
    margin-bottom: calc(var(--GW-SPACING-6) * 2);
    padding: calc(var(--GW-SPACING-5) * 3) calc(var(--GW-SPACING-5) * 7) calc(var(--GW-SPACING-5) * 5 ) calc(var(--GW-SPACING-5) * 5);

    &.basic {
        background-color: var(--GW-COLOR-BACKGROUND-1);
        border-left: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BACKGROUND-1);
    }

    &.extended {
        background-color: var(--GW-COLOR-BACKGROUND-1);
        border-left: var(--GW-BORDER-WIDTH) solid var(--GW-BRAND-COLOR-2);
    }

    &.highlighted {
        background-color: var(--GW-COLOR-BACKGROUND-4);
        border-left: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BACKGROUND-4);
    }

    h2 {
        font-family: var(--GW-FONT-SERIF);
        font-size: calc(var(--GW-SPACING-5)*2);
        font-weight: var(--GW-FONT-WEIGHT-BOLD);
        line-height: var(--GW-LINE-HEIGHT);
        margin: 0;
        padding-bottom: var(--GW-SPACING-5);
        position: relative;

        &:not(:last-child) {
            margin-bottom: var(--GW-SPACING-10);
        }

        &::after {
            position: absolute;
            display: block;
            height: var(--GW-BORDER-WIDTH-THIN);
            background: var(--GW-COLOR-STROKE);
            bottom: 0;
            left: 0;
            content: '';
            width: 100%;
        }
    }

    h3 {
        font-size: var(--GW-FONT-SIZE-H3);
        font-weight: var(--GW-FONT-WEIGHT-BOLD);
        line-height: var(--GW-LINE-HEIGHT);
        margin: 0;

        &:not(:last-child) {
            margin-bottom: calc(var(--GW-SPACING-6)*2);
        }
    }

    h4 {
        font-family: var(--GW-FONT-SERIF);
        font-size: var(--GW-FONT-SIZE-MD);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        letter-spacing: 0.5px;
        line-height: var(--GW-LINE-HEIGHT);
        margin: 0;
        text-transform: none;

        &:not(:last-child) {
            margin-bottom: var(--GW-SPACING-5);
        }
    }

    p {
        font-size: var(--GW-FONT-SIZE);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        letter-spacing: 0.5px;
        line-height: var(--GW-LINE-HEIGHT);
        margin: 0;

        &:not(:last-child) {
            margin-bottom: calc(var(--GW-SPACING-6)*2);
        }
    }
}