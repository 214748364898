@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.nfumCostInfoBoxContainer {
    background-color: var(--GW-BRAND-COLOR-1);
    padding: var(--GW-SPACING-5) calc(6 * var(--GW-SPACING-4));
    @include gw-breakpoint-only(phone){
        padding: var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(phoneWide){
        padding: var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(tablet){
        padding: var(--GW-SPACING-6);
    }
}

p.topText {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
}

h4.title {
    font-family: var(--GW-FONT-SERIF);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; 
    letter-spacing: 0.5px;
}

.premiumContainer {
    display: flex;
    align-items: flex-end;
    font-weight: bold;
    margin-top: -44px;
    max-width: 50%;
    @include gw-breakpoint-only(phone){
        margin-top: -10px;
    }
}

.amount {
    font-size: 46px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0.5px;
}

.perTime {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-left: 8px;
    margin-bottom: 24px;
    min-width: fit-content;
    @include gw-breakpoint-only(phone){
        margin-bottom: 5px;
    }
}

p.additionalText {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.costBreakdownContainer {
    margin-top: 0px;
    @include gw-breakpoint-only(phone){
        margin-top: 80px;
    }
    @include gw-breakpoint-only(phoneWide){
        margin-top: var(--GW-SPACING-0);
    }
}

h4.costBreakdownTitle {
    font-family: var(--GW-FONT-SERIF);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; 
    letter-spacing: 0.5px;
    padding: 8px 0px;
}

.costInfoTitle {
    border-top: 1px solid #2D2C2C;
    border-bottom: 1px solid #2D2C2C;
    margin-top: 8px;
    padding: 8px;
    padding-top: 0px;
}

.costInfoTitleText {
    color: #2D2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.costInfoTableHeader {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    border-bottom: 1px solid #2D2C2C;
}

.costInfoCoverNameHeader {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.5px;
    width: 33%;
}

.costInfoAmountHeader {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.5px;
    width: 33%;
    text-align: center;
}

.costInfoTableRow {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    border-bottom: 1px dashed #2D2C2C;
}

.costInfoCoverNameColumn {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.costInfoAmountColumn {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
    width: 33%;
    text-align: center;
}

.normalText {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.boldText {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.costInfoTableRowExtended {
    display: flex;
    padding: 16px 8px;
    justify-content: space-between;
    border-bottom: 1px dashed #2D2C2C;
    flex-direction: column;
    row-gap: 8px;
}

.costsPerLocationContainer {
    margin-bottom: 46px;
    @include gw-breakpoint-only(phone){
        margin-bottom: var(--GW-SPACING-8);
    }
    @include gw-breakpoint-only(phoneWide){
        margin-bottom: var(--GW-SPACING-8);
    }
    @include gw-breakpoint-only(tablet){
        margin-bottom: var(--GW-SPACING-8);
    }
}

.costsPerLocationContainer:last-of-type {
    margin-bottom: 0px;
}

.editQuoteCls {
    font-size: 25px;
    font-weight: bold;
    text-align-last: end;
    margin-top: 10px;
    border: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-DARK) !important;
    &:global(.jut__Button__filled:hover:not(.jut__Button__disabled)) {
        background: var(--NFUM-COLOR-WHITE);
        border: none !important;
    }
    &:global(.jut__Button__filled:hover) {
        color: var(--NFUM-COLOR-DARK);
    }
    &:global(.jut__Button__filled:hover .jut__Button__icon) {
        color: var(--NFUM-COLOR-DARK);
    }
}

.premiumTypeCls {
    border-bottom: 2px solid black;
}

.switchTypeContainer {
    text-align: end;
    font-weight: bold;
    @include gw-breakpoint-only(phone){
        text-align: left;
        margin-top: 140px;
        position: absolute;
    }
}

.iconCls {
    font-size: 10px;
}

.editCoverDivCls {
    display: block;
    text-align: right;
}