@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);
.subHeading {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
    padding-top: var(--GW-SPACING-3);
    max-width: 100%;
}

.paymentHeading {
    max-width: 100%;
}

.confirmingProvidedDetailsContainer {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    padding-left: var(--GW-SPACING-6);
    @include gw-breakpoint-only(phone){
        padding-left: var(--GW-SPACING-5);
        padding-right: var(--GW-SPACING-5);
    }
}

.additionalInfoStyle {
    font-size: 1rem;
}

.additionalInfoDivStyle {
    max-width: 100%;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.bold {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

.confirmingProvidedDetailsCheckbox {
    max-width: 100%;
    letter-spacing: 0.5px;
    padding: var(--GW-SPACING-5) 0 var(--GW-SPACING-8) var(--GW-SPACING-2);
    @include gw-breakpoint-only(phone){
        padding: var(--GW-SPACING-5) 0 var(--GW-SPACING-5) var(--GW-SPACING-2);
        margin-top: var(--GW-SPACING-0) !important;
    }
    @include gw-breakpoint-only(phoneWide){
        padding: var(--GW-SPACING-5) 0 var(--GW-SPACING-5) var(--GW-SPACING-2);
        margin-top: var(--GW-SPACING-0) !important;
    }
    @include gw-breakpoint-only(tablet){
        padding: var(--GW-SPACING-5) 0 var(--GW-SPACING-5) var(--GW-SPACING-2);
        margin-top: var(--GW-SPACING-0) !important;
    }
}

.checkboxLabel {
    padding-left: var(--GW-SPACING-5);
    line-height: var(--GW-SPACING-6);
    @include gw-breakpoint-only(phone){
        padding-left: var(--GW-SPACING-0);
        font-size: var(--GW-FONT-SIZE-SM);
    }
    @include gw-breakpoint-only(phoneWide){
        padding-left: var(--GW-SPACING-0);
        font-size: var(--GW-FONT-SIZE-SM);
    }
    @include gw-breakpoint-only(tablet){
        padding-left: var(--GW-SPACING-0);
        font-size: var(--GW-FONT-SIZE-SM);
    }
    span{
       margin-right: var(--GW-SPACING-0);
    }
}

.costContainer {
    margin-top: var(--GW-SPACING-5);
}

.costInput {
    margin-top: -0.4rem !important;
}

label {
    color: var(--NFUM-FONT-COLOR-PRIMARY);
    font-weight: var(--GW-FIELD-COMPONENT-FONT-WEIGHT);
    display: inline-block;
    position: relative;
    font-size: var(--GW-FONT-SIZE-LABEL);
    line-height: var(--GW-LINE-HEIGHT-LABEL);
}

.additionalInfo {
    color: var(--NFUM-COLOR-TABLE-BORDERS-GREY);
    font-size: var(--GW-FONT-SIZE-SM);
    padding-bottom: 2rem;
}

.infoSize {
    font-size: 0.8rem;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.additionalInfoContainer {
    margin-bottom: var(--GW-SPACING-6);
    padding-top: 1rem;
}

.labelColor {
    --NFUM-FONT-COLOR-PRIMARY: black;
}

.overridableField {
    margin-bottom: 0.5rem;
    :global(.jut__CurrencyField__inputContainer):before {
        font-family: "NFU Icons", sans-serif;
        content: "\f11a";
        color: black;
        margin-right: var(--GW-SPACING-4);
    }
}

.yearInputWidth {
    width: 100%;
}