@mixin generate($prefix, $property) {
    // eslint-disable-next-line max-len
    $sizes: [2, 3, 4, 5, 6, 8, 10]; /* NOSONAR: It is a common issue in SonarQube when parsing SCSS file */
    
    @for $i from 1 through length($sizes) {
        $size: nth($sizes, $i);
    
        .#{$prefix}#{$size} {
            #{$property}: var(--GW-SPACING-#{$size}) !important;
        }
    }
}