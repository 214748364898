@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.section {
    border: none;

    &.basic {
        background-color: var(--GW-COLOR-BACKGROUND-1);
    }

    &.extended {
        background-color: var(--GW-COLOR-BACKGROUND-1);
        border-left: var(--GW-COLOR-BACKGROUND-1);
    }

    &.highlighted {
        background-color: var(--GW-COLOR-BACKGROUND-4);
        border-left: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BACKGROUND-4);
    }

    .sectionContent {
        padding: 24px 72px;
        color: #2D2C2C;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Meta Pro, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.5px;

        @include gw-breakpoint-down(phone) {
            padding: var(--GW-SPACING-5) var(--GW-SPACING-5);
        }
    }

    .sectionTitle {
        .headerCircleContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            padding: var(--GW-SPACING-3);
            margin-right: var(--GW-SPACING-4);
            background-color: var(--NFUM-COLOR-YELLOW-MAIN);
        }
        h2 {
            font-family: var(--GW-FONT-SERIF);
            @include gw-breakpoint-only(phone){
                font-size: var(--GW-FONT-SIZE-H4);
            }
        }
        display: flex;
        align-items: center;
        padding: 16px 72px;
        border-bottom: calc(var(--GW-BORDER-WIDTH)) solid var(--NFUM-COLOR-YELLOW-MAIN);
        gap: 16px;

        @include gw-breakpoint-down(phone) {
            padding: var(--GW-SPACING-5) var(--GW-SPACING-5);
        }
    }
}


.iconContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.icon {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
}

.paddingRightStyle {
    font-size: 18px;
    font-weight: 400;
    margin-left: auto;
    @include gw-breakpoint-down(phone){
        font-size: 16px;
    }
}

.tickmarkIcon {
    width: 33px;
    height: 31px;
    composes: circle_tickmark from global;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 0.5rem;
    @include gw-breakpoint-only(phone){
        background-size: 52%;
        margin-left: 0.2rem;
    }
}

.noSectionClass {
    padding: 0;
}