@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.link {
  text-decoration: underline;
}

.differentAddressContainer {
  margin-top: var(--GW-SPACING-6);
  margin-bottom: var(--GW-SPACING-6);
  width: 51%;
  font-size: 16px;
  @include gw-breakpoint-only(phone){
    width: 100%;
  }
  @include gw-breakpoint-only(phoneWide){
    width: 100%;
  }
  @include gw-breakpoint-only(tablet){
    width: 100%;
  }
}

.errorMessage {
  color: var(--GW-COLOR-ERROR);
  margin-top: var(--GW-SPACING-2);
  display: block;
}

.maximumWidth {
  width: 55%;
}

.postCodeWidth {
  width: 60%;
  @include gw-breakpoint-only(phone){
    width:100%;
  }
  @include gw-breakpoint-only(phoneWide){
    width:100%;
  }
  @include gw-breakpoint-only(tablet){
    width:100%;
  }
}