@import '~@jutro/theme/assets/sass/helpers';

h5.newPolicyStartDate {
  margin-bottom: var(--GW-SPACING-6);
}

.saveNewQuoteContainer {
  --save-new-quote-container-max-width: 722px;
  --save-new-quote-container-margin: 0 auto;
  --save-new-quote-container-padding: 64px 16px 0;

  width: 100%;
  max-width: var(--save-new-quote-container-max-width);
}

.saveNewQuoteContentContainer {
  --save-new-quote-input-container-bg-color: var(--GW-COLOR-BACKGROUND-1);
  --save-new-quote-input-container-padding: 0 calc(var(--GW-SPACING-5) * 5)
    calc(var(--GW-SPACING-5) * 3) calc(var(--GW-SPACING-5) * 5);

  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: var(--save-new-quote-input-container-bg-color);
  padding: var(--save-new-quote-input-container-padding);
  .newPolicyStartDate {
    margin-top: calc(var(--GW-SPACING-6) * 2);
  }
}

.saveNewQuoteButtonContainer {
  --save-new-quote-button-container-margin-x: var(--GW-SPACING-6);
  --save-new-quote-button-margin-x: var(--GW-SPACING-2);

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: var(--save-new-quote-button-container-margin-x) 0;

  @include gw-breakpoint-down(tablet) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    order: 0;

    & button {
      margin: var(--save-new-quote-button-margin-x) 0;
    }
  }
}

.saveNewQuoteAdditionalButtonContainer {
  display: flex;
  @include gw-breakpoint-down(tablet) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    order: 1;
  }
}