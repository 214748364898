@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);
$pageMaxWidth: 1500px;

.progressBarContainer {
    --nfum-progress-bar-padding: 1rem calc(var(--GW-SPACING-5)*3) 1rem 12rem;
    --nfum-progress-icon-size: 20px;
    --nfum-progress-bar-header-min-height: 10rem;
    --nfum-progress-bar-header-min-height-none: 0rem;


    @include gw-breakpoint-down(phoneWide) {
        --nfum-progress-bar-padding: var(--GW-SPACING-4) var(--GW-SPACING-6) var(--GW-SPACING-6);
        --nfum-progress-bar-header-min-height: 8rem;
    }
    @include gw-breakpoint-only(tablet) {
        --nfum-progress-bar-padding: var(--GW-SPACING-4) 8rem var(--GW-SPACING-6);
    }

    padding: var(--nfum-progress-bar-padding);
    border-top: solid var(--GW-BORDER-WIDTH-THIN) var(--GW-COLOR-TEXT-1);
    width: 100%;
    min-height: var(--nfum-progress-bar-header-min-height);
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: $pageMaxWidth;

    .progressBarHeader {
        padding-top: calc(var(--GW-SPACING-2)*3);
        width: 100%;
        display: grid;
        grid-gap: var(--GW-SPACING-5);
        grid-template-columns: auto auto;
        grid-template-areas: "title quotereference";
        align-items: start;

        @include gw-breakpoint-down(phone) {
            grid-template-areas: "title" "quotereference";
        }
    }

    .progressBarTitle {
        grid-area: title;
        h2{
           font-size: var(--GW-FONT-SIZE-H1);
           font-family: var(--GW-FONT-SERIF);
           font-weight: var(--GW-FONT-WEIGHT-THIN);
        }
        @include gw-breakpoint-down(phone) {
            font-size: var(--GW-FONT-SIZE-H2); 
        }
    }

    .quoteReference {
        grid-area: quotereference;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        color: var(--NFUM-FONT-COLOR-PRIMARY);

        @include gw-breakpoint-down(phone) {
            align-items: flex-start;
        }
    }
    
    .progressBar {
        display: flex;
        column-gap: var(--GW-SPACING-3);
        padding-top: var(--GW-SPACING-8);
        padding-bottom: var(--GW-SPACING-8);
        @include gw-breakpoint-only(phone){
            padding-bottom: var(--GW-SPACING-0);
        }
        @include gw-breakpoint-only(phoneWide){
            padding-bottom: var(--GW-SPACING-4);
        }
        @include gw-breakpoint-only(tablet){
            padding-bottom: var(--GW-SPACING-4);
        }
    }
    
    .navigationButton {
        flex: 1 1 auto;
        background: none;
        border: none;
        border-top: calc(var(--GW-BORDER-WIDTH-THIN)*2) solid var(--GW-BRAND-COLOR-1);
        padding-top: var(--GW-SPACING-5);
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        min-width: 2rem;

        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);
        }
    }

    .active {
        cursor: default;
        border-top: calc(var(--GW-BORDER-WIDTH-THIN)*2) solid var(--GW-BRAND-COLOR-1);

        .stepIcon {
            background: var(--GW-COLOR-BACKGROUND-1);
            border: calc(var(--GW-BORDER-WIDTH-THIN)*2) solid var(--GW-BRAND-COLOR-1);

        }
    }

    button.disabled {
        cursor: not-allowed;
    }
    
    .notVisited {
        cursor: default;
        border-top: calc(var(--GW-BORDER-WIDTH-THIN)*2) solid var(--GW-COLOR-STROKE);

        .stepIcon {
            background: var(--GW-COLOR-STROKE);
        }       
    }

    .progressInfo {
        display: none;

        .stepInfoIcon {
            width: var(--nfum-progress-icon-size);
            height: var(--nfum-progress-icon-size);
            background: var(--GW-COLOR-BACKGROUND-1);
            border: calc(var(--GW-BORDER-WIDTH-THIN)*2) solid var(--GW-BRAND-COLOR-1);
            border-radius: 50%;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: none;
        }
    
        .stepInfoText {
            margin-left: var(--GW-SPACING-3);
        }

        @include gw-breakpoint-down(phone) {
            display: block;
        }
    }
    
    .stepIcon {
        width: var(--nfum-progress-icon-size);
        height: var(--nfum-progress-icon-size);
        background: var(--GW-BRAND-COLOR-1);
        border-radius: 50%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: none;
        padding: 4px;

        @include gw-breakpoint-down(phone) {
            display: none;
        }
    }
    
    .stepIcon span {
        color: var(--NFUM-FONT-COLOR-PRIMARY);
        font-size: var(--GW-FONT-SIZE-SUBTEXT);
    }
    
    .stepTitle {
        font-size: var(--GW-FONT-SIZE-SUBTEXT);
        padding-left: var(--GW-SPACING-5);
        flex: auto;
        text-align: left;
        margin-right: var(--GW-SPACING-3);
        line-height: var(--nfum-progress-icon-size);
        color: var(--NFUM-FONT-COLOR-PRIMARY);

        @include gw-breakpoint-down(phone) {
            display: none;
        }
    }
}

.container {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    display: flex;
    justify-content: center;
}

.additionalInfo {
    margin-top: calc(var(--GW-SPACING-8)*2);
    font-size: var(--NFUM-FONT-SIZE-16)
}

.tabKeyArrows{
    background-color: transparent;
    padding: 0px;
    width: auto;
    display: flex;
    border-top-color: black;
    min-width: 2rem;
    padding-top: var(--GW-SPACING-5);
}