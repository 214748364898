@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.jut__FieldComponent__fieldComponent {
    margin-top: var(--GW-SPACING-5);
    @include gw-breakpoint-only(phone){
        margin-top: var(--GW-SPACING-1);
    }
}

.jut__DatePickerField__readOnly,
.jut__FieldComponent__controlReadOnly {
    font-weight: normal;
}


.jut__FieldLabel__top {
    margin-bottom: var(--GW-SPACING-4);
}

.jut__FieldLabel__fieldLabel {
    color: var(--NFUM-FONT-COLOR-PRIMARY);
}

.jut__TooltipIcon__tooltipIcon {
    color: var(--NFUM-FONT-COLOR-PRIMARY);
}

header.jut__Header__header {
    --gw-header-image-height: 78px;
    --gw-header-image-width: 170px;
    background: white;
    border-bottom: none;
    box-shadow: none;
}

.jut__IFrame__iframeContainer {
    height: 100%;
}

.jut__IFrame__iframe {
    border: none;
}

.jut__FieldLabel__hidden {
    display: none;
}

.jut__Accordion__accordion .jut__Accordion__headerFocused {
    border: unset;
    box-shadow: unset;
}

.jut__GenericSelectControl__option {
    border-left: 2px solid var(--NFUM-BORDER-LEFT-COLOR);
    margin-top: 6px;
}

.jut__RadioButton__radioButton[aria-label="true"] {
    border: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
}

.jut__CheckboxField__checkboxIcon {
    color: black;
}

.jut__CheckboxField__checkboxElementWrapper {
    padding-left: var(--GW-SPACING-5);
}

.jut__Button__filled:hover, .jut__Button__primary:hover, .jut__Button__danger:hover {
    color: var(--NFUM-COLOR-WHITE);
}

.jut__Button__outlined:hover, .jut__Button__neutral:hover {
    border: var(--GW-BORDER-WIDTH) solid var(--GW-BUTTON-OUTLINED-COLOR);
}

.jut__Button__text {
    background-color: transparent;
    color: var(--GW-BUTTON-TEXT-COLOR);
    > span {
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-YELLOW-MAIN);
    }
}

button.jut__Button__outlined,
button.jut__Button__outlined[disabled],
button.jut__Button__outlined[disabled]:hover {
    background-color: transparent;
    color: var(--NFUM-COLOR-GREY);
    border: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-YELLOW-MAIN);
    box-shadow: none;
}

.jut__Button__text:hover{
    background-color: transparent;
    > span {
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BUTTON-TEXT-COLOR);
    }
}

.jut__Button__text:active,
.jut__Button__text:active:focus,
.jut__Button__text:active:focus:hover {
    background-color: transparent;
}

.radioButton > div > div > div[role=radiogroup] > div, .propertyStyling > div > div[role=radiogroup] > div{
    display: flex;
    align-items: center;
    box-shadow: var(--GW-SHADOW-2);
    padding: var(--GW-SPACING-4) var(--GW-SPACING-5);
    margin-bottom: var(--GW-SPACING-5);
}

.jut__DataTable__clickableRow {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-FIELD-DISABLED);
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody {
    border-bottom: unset;
}

.jut__DataTable__expandableRowWrapper {
    padding: unset;
}

.jut__DatePickerField__dateHeader {
    .jut__DatePickerField__control {
        width: auto;
    }
}

.gw-header {
    --nfum-header-padding: 1rem calc(var(--GW-SPACING-5)*3) 1rem calc(var(--GW-SPACING-5)*3);

    @include gw-breakpoint-down(phoneWide) {
        --nfum-header-padding: var(--GW-SPACING-4) var(--GW-SPACING-6) var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(tablet) {
        --nfum-header-padding: var(--GW-SPACING-4) 8rem var(--GW-SPACING-6);
    }

    padding: var(--nfum-header-padding);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.gw-header-img {
    @include gw-breakpoint-down(tablet) {
        width: 12rem;
        height: 5.5rem;
    }
}

.jut__ModalNext__modal {
    min-width: 100% !important;
    background: transparent !important;
    box-shadow: none !important;
}

.jut__ConfirmationModal__overlayConfirmation {
    .jut__ModalNext__modal {
        display: flex;
        justify-content: center;

        .jut__ModalNext__modalLayout {
            width: 40%;
            background: var(--NFUM-COLOR-WHITE);
            @include gw-breakpoint-only(phone){
                width: 80%;
            }
        }
    }
}

.jut__ModalHeader__modalHeaderLayout {
    display: unset;
    i {
        font-size: var(--GW-LAYOUT-12);
    }
    .jut__ModalHeader__closeButton {
        display: none;
    }
}

.jut__ModalHeader__iconWarning {
    color: var(--NFUM-COLOR-YELLOW-MAIN);
    
}

.jut__ModalFooter__modalFooter {
    background-color: unset;
    >div {
        display: block;
        .jut__Button__text {
            >span::after {
                font-family: 'Material Icons', sans-serif;
                content: 'close';
                -webkit-font-feature-settings: 'close';
                padding-left: var(--GW-SPACING-4);
                font-size: x-large;
                font-weight: normal;
            }
        }
        .jut__Button__filled {
            >span::after {
                font-family: 'Material Icons', sans-serif;
                content: '';
                padding-left: var(--GW-SPACING-4);
                font-size: x-large;
            }
        }
    }
}

.jut__Accordion__accordion .jut__Accordion__accordionFrame {
    border: none;
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape.jut__RadioButtonCardField__disabled:active:focus:hover,
.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape.jut__RadioButtonCardField__disabled:active:focus,
.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape.jut__RadioButtonCardField__disabled:focus,
.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape.jut__RadioButtonCardField__disabled:active,
.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape.jut__RadioButtonCardField__disabled {
    opacity: 1;
    box-shadow: none;
    background-color: #f1f1f1;
    cursor: not-allowed;
    border: 2px solid transparent;

    .jut__RadioButtonCardField__radioWrapperLandscape {
        box-shadow: none;
        background-color: transparent; 
    }

    .jut__RadioButtonCardField__radioWrapperLandscape:before {
        border-width: 1px;
        background-color: #f1f1f1;
        border-color: #5D5D5D;
    }

    .jut__RadioButtonCardField__radioWrapperLandscape:after {
        background-color: transparent;
    }

    .jut__RadioButtonCardField__iconContainerLandscape {
        opacity: 0.6;
    }

    .jut__RadioButtonCardField__contentWrapperLandscape .jut__RadioButtonCardField__nameLandscape {
        color: #5D5D5D;
    }
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape {
    .jut__RadioButtonCardField__radioWrapperLandscape {
        position: absolute;
        margin-left: 8px;
        margin-top: 20px;
        border: none;
        width: 48px;
        height: 48px;
        background-color: transparent;
        border-radius: 100%;
        content: "";
        transform: translate(-12px, -50%);
        transition-delay: 150ms;
        transition-duration: 250ms;
        transition-property: background-color, border-color;
    }

    .jut__RadioButtonCardField__radioWrapperLandscape:before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border: 1px solid black;
        background-color: white;
        content: "";
        outline: 1px solid transparent;
        outline-offset: -2px;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        transition-delay: 150ms;
        transition-duration: 250ms;
        transition-property: background-color, border-color;
    }

    .jut__RadioButtonCardField__radioWrapperLandscape:after {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: transparent;
        color: transparent;
        content: "";
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        transition-duration: 150ms;
        transition-property: color, background-color, border-color;
        width: 10px;
        height: 10px;
        border-radius: 100%;
    }
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled) {
    .jut__RadioButtonCardField__radioWrapperLandscape.jut__RadioButtonCardField__radioWrapperChecked:before {
        border-width: 2px;
        border-color: black;
    }

    .jut__RadioButtonCardField__radioWrapperLandscape.jut__RadioButtonCardField__radioWrapperChecked:after {
        background-color: black;
    }
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:focus {
    .jut__RadioButtonCardField__radioWrapperLandscape {
        box-shadow: none;
    }
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled):not([aria-checked="true"]):active:focus:hover,
.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled):not([aria-checked="true"]):active:focus,
.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled):not([aria-checked="true"]):active:hover,
.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled):not([aria-checked="true"]):active {
    .jut__RadioButtonCardField__radioWrapperLandscape {
        box-shadow: none;
        background-color: #ffd738;
        transition-delay: 0s;
        transition-duration: 0s;
    }

    .jut__RadioButtonCardField__radioWrapperLandscape:before {
        border-width: 1px;
        border-color: black;
        box-shadow: 0px 3px 6px rgba(255, 203, 5, 0.2);
        transition-delay: 0s;
        transition-duration: 0s;
    }

    .jut__RadioButtonCardField__radioWrapperLandscape:after {
        background-color: black;
    }
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled):hover {
    .jut__RadioButtonCardField__radioWrapperLandscape:not(.jut__RadioButtonCardField__radioWrapperChecked):before {
        border-width: 2px;
        border-color: var(--NFUM-COLOR-YELLOW-MAIN);
        box-shadow: 0px 3px 6px rgba(255, 203, 5, 0.2);
    }

    .jut__RadioButtonCardField__radioWrapperLandscape:not(.jut__RadioButtonCardField__radioWrapperChecked):after {
        background-color: #f1f1f1;
    }
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled)[aria-checked="true"] {
    border: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape.jut__RadioButtonCardField__disabled[aria-checked="true"] {
    border: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);

    .jut__RadioButtonCardField__radioWrapperLandscape.jut__RadioButtonCardField__radioWrapperChecked:before {
        border-width: 2px;
        border-color: black;
    }

    .jut__RadioButtonCardField__radioWrapperLandscape.jut__RadioButtonCardField__radioWrapperChecked:after {
        background-color: black;
    }
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape {
    border: 2px solid transparent;
    background-color: transparent;
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not([aria-checked="true"]):not(.jut__RadioButtonCardField__disabled):hover {
    border: 2px solid var(--NFUM-BODY-BACKGROUND-COLOR-1);
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled):not([aria-checked="true"]):active:focus:hover,
.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled):not([aria-checked="true"]):active:hover,
.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled):not([aria-checked="true"]):active:focus,
.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__cardLandscape:not(.jut__RadioButtonCardField__disabled):not([aria-checked="true"]):active {
    border: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
    background-color: var(--NFUM-COLOR-YELLOW-MAIN);
}

.jut__RadioButtonCardField__radioButtonCard .jut__RadioButtonCardField__contentWrapperLandscape .jut__RadioButtonCardField__nameLandscape {
    width: 70%;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    @include gw-breakpoint-only(phone) {
        width: 100%;
    }
}
.jut__RadioButtonCardField__contentWrapperLandscape{
    @include gw-breakpoint-only(phone) {
         padding: 0 !important;
    }  
}

.jut__Table__table {
    padding: 0;
}

.jut__FieldMessage__fieldMessage {
    display: block;
}
.jut__RadioButton__radioButton[aria-checked=true]{
    border: 2px solid var(--NFUM-COLOR-YELLOW-MAIN);
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
}

.jut__ModalFooter__modalFooter {
	.jut__Flex__gwDirectionRow.jut__Flex__gwGapMedium {
       display: flex;
       justify-content: flex-start;
    }
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell:focus,
.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell:focus {
    box-shadow: none;
}

.ReactTable.-highlight .rt-tbody div.rt-tr:not(.-padRow):hover {
    background-color: transparent;
}

.jut__ToggleField__toggle .jut__ToggleField__toggleButton.jut__ToggleField__active:not([aria-disabled]) {
    border: none;
    box-shadow: none;
    outline: none;
}

.jut__ToggleField__toggle .jut__ToggleField__toggleButton.jut__ToggleField__active:focus:not([aria-disabled]) {
    border: none;
    outline: none;
    box-shadow: 0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);
}

.jut__ToggleField__toggle .jut__ToggleField__toggleButton:active:not([aria-disabled]), 
.jut__ToggleField__toggle .jut__ToggleField__toggleButton:active:focus:not([aria-disabled]) {
    box-shadow: none;
    border: none;
    outline: none;
}

.jut__ToggleField__toggle .jut__ToggleField__toggleButton.jut__ToggleField__left:focus,
.jut__ToggleField__toggle .jut__ToggleField__toggleButton.jut__ToggleField__middle:focus,
.jut__ToggleField__toggle .jut__ToggleField__toggleButton.jut__ToggleField__right:focus {
    outline: none;
    border: none;
}

.mmStyle {
    letter-spacing: -2px;
}