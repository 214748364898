@import '~@jutro/theme/assets/sass/helpers';

.valuablesAwayContent {
    padding: 24px 72px;
    @include gw-breakpoint-only(phone){
        padding: 24px 20px;
    }
}

.valuablesAwayNotice {
    background-color: #FFF5D1;
    padding: 24px 72px;
}

.valuablesAwayContentDescription {
    display: flex;
    justify-content: space-between;
}

.enableValuablesAwaySwitchContainer {
    width: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.bold {
    font-weight: bold;
}
