@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
  phone: 768px,
  phoneWide: 1024px,
  tablet: 1500px
);

.declarationContainer {
    margin-top: var(--GW-SPACING-0);
    .showLinksText a span {
        text-decoration: underline;
        font-size: 16px;
    }
}

.coverageWarningText {
    color: var(--colours-sematics-error);
}

.bold {
    font-weight: bold;
}

.paddingStyle {
    padding-bottom: 1.5rem;
}

.continue1Style {
    margin-top: 1.5rem;
}

.paddingStyles {
    padding-right: 10rem;
}

.headerStyle {
    h2 {
        color: #757575;
    }
}

.prepopulationLabelCls {
    border-style: solid;
    border-width: var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN)
        var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH);
    padding: var(--GW-SPACING-3) 0 var(--GW-SPACING-3) var(--GW-SPACING-3);
    margin-bottom: 2rem;
    line-height: calc(var(--GW-SPACING-3) * 4);
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    padding-left: 1.2rem;
    padding-right: 20rem;
    border-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-YELLOW-MAIN);
    font-size: 1rem;
}

.maxWidth {
    width: 60%;
    @include gw-breakpoint-only(phone){
        width: 100%;
    }
    @include gw-breakpoint-only(phoneWide){
        width: 100%;
    }
    @include gw-breakpoint-only(tablet){
        width: 100%;
    }
}

.fontSize {
    font-size: 16px;
    ul{
        padding-left: var(--GW-SPACING-8);
        padding-top: var(--GW-SPACING-5);
        margin-bottom: var(--GW-SPACING-0);
        li{
            line-height: var(--GW-SPACING-8);
            padding-bottom: var(--GW-SPACING-5);
        }
    }
}

.maximumWidth {
    width: 75%;
}

.dateOfBirthCls {
    margin-bottom: 1rem;
    :global(.jut__SimpleDateField__hidden) {
        clip: auto;
        overflow: visible;
        padding: 0;
        position: absolute;
        width: auto;
        padding-top: 45px;
        font-size: 12px;
        font-weight: bold;
        margin-left: 11px;
      }
      :global(.jut__OldFieldMessage__error) {
        margin-top: 25px;
      }
}
.labelMargin {
    margin-top: 0;
}
