@import 'mixins/margin-mixin.scss';

@include generate(nfumMarginTop, margin-top);
@include generate(nfumMarginBottom, margin-bottom);
@include generate(nfumMarginLeft, margin-left);
@include generate(nfumMarginRight, margin-right);

.nfumMarginTop0 {
    margin-top: 0px !important;
}

.nfumMarginBottom0 {
    margin-top: 0px !important;
}

.nfumBold {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

.nfumFontSize16 {
    font-size: var(--NFUM-FONT-SIZE-16);
}
