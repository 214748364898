$primary-color: #FFCB05;
$text-color: #2D2C2C;
$background-color: white;

.container {
    min-width: 345px;
    background-color: $background-color;
    padding: 24px;
}

p.headerText {
    font-weight: 600;
}

.imageContainer {
    text-align: center;
    margin-bottom: 24px;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
}

p.text {
    text-align: center;
    font: 400 14px / 24px "Meta Pro", sans-serif;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: none;
    color: $text-color;
}

.hidden {
    display: none;
}