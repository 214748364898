.modalWrapper {
    display: flex;
    justify-content: center;
}

.modal {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    overflow-y: auto;
    padding: 0;
    max-height: 90vh;
    width: 70%;
}

.modalHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: var(--GW-SPACING-6) var(--GW-SPACING-10) var(--GW-SPACING-6) var(--GW-SPACING-10);
    position: sticky;
    background: var(--GW-COLOR-BACKGROUND-1);
    top: 0;
    left: 0;
    z-index: 101;
    h5 {
        font-family: var(--GW-FONT-SERIF);
        font-size: var(--GW-FONT-SIZE-MD);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        letter-spacing: 0.5px;
        line-height: var(--GW-LINE-HEIGHT);
        text-transform: none;
    }

    span {
        flex-shrink: 0;
    }
}

.modalTitle {
    flex: 1;
}

.modalHeaderBorderBottom {
    border-bottom: calc(var(--GW-BORDER-WIDTH)) solid var(--NFUM-COLOR-YELLOW-MAIN);
}

.modalContent {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-10) var(--GW-SPACING-6) var(--GW-SPACING-10);
}

.modalFooter {
    padding: 0 var(--GW-SPACING-10) var(--GW-SPACING-10) var(--GW-SPACING-10);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.bold {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

.highValueBicyclesModalIcon {
    margin-right: var(--GW-SPACING-4);
    composes: circle_high-value from global;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
}

.cancelButton,
.saveAndAddButton {
    margin-bottom: var(--GW-SPACING-6);
}