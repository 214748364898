@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);
$pageMaxWidth: 1500px;
$referralMaxWidth: 660px;
$referralMaxWidthSmallScreens: 100%;

.uwIssuePageWrapper {
    width: 100%;
}

.titleContainer {
    width: 100%;
    background: var(--GW-COLOR-BACKGROUND-1);
}

.progressBarContainer {
    --nfum-progress-bar-padding: 0rem var(--GW-SPACING-6) 0rem 12rem;
    --nfum-progress-bar-header-padding-top: calc(var(--GW-SPACING-5)*5);
    --nfum-progress-bar-header-min-height: 10rem;
    --nfum-progress-icon-size: 20px;

    @include gw-breakpoint-down(phoneWide) {
        --nfum-progress-bar-padding: var(--GW-SPACING-4) var(--GW-SPACING-6) var(--GW-SPACING-6);
        --nfum-progress-bar-header-padding-top: var(--GW-SPACING-8);
        --nfum-progress-bar-header-min-height: 8rem;
    }
    @include gw-breakpoint-only(tablet) {
        --nfum-progress-bar-padding: var(--GW-SPACING-4) 8rem var(--GW-SPACING-6);
    }

    width: 100%;
    display: flex;
    justify-content: center;
    min-height: var(--nfum-progress-bar-header-min-height);

    .progressBarHeader {
        border-top: solid var(--GW-BORDER-WIDTH-THIN) var(--GW-COLOR-TEXT-1);
        padding: var(--nfum-progress-bar-padding);
        padding-top: var(--nfum-progress-bar-header-padding-top);
        width: 100%;
        max-width: $pageMaxWidth;
        display: grid;
        grid-gap: var(--GW-SPACING-5);
        grid-template-columns: auto auto;
        grid-template-areas: "title references";
        align-items: start;

        @include gw-breakpoint-down(phoneWide) {
            grid-template-areas: "title" "references";
        }
    }

    .progressBarTitle {
        grid-area: title;
        font-size: var(--GW-FONT-SIZE-H1);
        font-family: var(--GW-FONT-SERIF);
    }

    .reference {
        grid-area: references;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        height: 100%;
        justify-content: top;
        color: var(--NFUM-FONT-COLOR-PRIMARY);

        @include gw-breakpoint-down(phoneWide) {
            align-items: flex-start;
        }
    }
    
    .progressBar {
        display: flex;
        column-gap: var(--GW-SPACING-3);
        padding-top: var(--GW-SPACING-10);
    }
}


.uwIssueReferralWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.uwIssueReferralContainer {
    --nfum-footer-info-padding: var(--GW-SPACING-10) 12rem var(--GW-SPACING-10) 12rem;

    @include gw-breakpoint-down(phone) {
        --nfum-footer-info-padding: var(--GW-SPACING-4) var(--GW-SPACING-6) var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(phoneWide) {
        --nfum-footer-info-padding: var(--GW-SPACING-10) var(--GW-SPACING-6) var(--GW-SPACING-10) var(--GW-SPACING-6);
    }
    @include gw-breakpoint-only(tablet) {
        --nfum-footer-info-padding: var(--GW-SPACING-10) 8rem var(--GW-SPACING-10) 8rem;
    }

    width: 100%;
    max-width: $pageMaxWidth;
    padding: var(--nfum-footer-info-padding);
    display: inline;
}

.rightColumn {
    @include gw-breakpoint-down(tablet) {
        flex: 0;
    }
    flex: 1
}

.leftColumn {
    flex: 3;
    max-width: $referralMaxWidth;
    @include gw-breakpoint-only(phone){
        max-width: $referralMaxWidthSmallScreens;
    }
    @include gw-breakpoint-only(phoneWide){
        max-width: $referralMaxWidthSmallScreens;
    }
}

.buttonsContainer {
    margin-top: var(--GW-SPACING-6);
    float: left;
}

.uwIssueReferral {
    --nfum-referral-padding: var(--GW-SPACING-10);

    @include gw-breakpoint-down(phone) {
        --nfum-referral-padding: var(--GW-SPACING-8) var(--GW-SPACING-4);
        min-width: auto;
    }
    @include gw-breakpoint-only(phoneWide) {
        --nfum-referral-padding: var(--GW-SPACING-8);
    }

    grid-area: info;
    display: flex;
    flex-direction: column;
    background-color: var(--GW-COLOR-BACKGROUND-1);
    padding: var(--nfum-referral-padding);
    border-left: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-BRAND-1);

    p {
        font-family: var(--GW-FONT-SANS-SERIF);
        font-size: var(--NFUM-FONT-SIZE-16);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        line-height: 24px;
        letter-spacing: 0.5px;
        margin: 0;
    }

    .title {
        font-size: var(--NFUM-FONT-SIZE-24);
        font-family: var(--GW-FONT-SERIF);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        line-height: 40px;
        letter-spacing: 0.5px;
        margin-bottom: var(--GW-SPACING-5);
    }
}
