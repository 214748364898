.hardStopMessageContainer {
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    padding: var(--GW-SPACING-8);
    margin-bottom: var(--GW-SPACING-8);
    border-top: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-BRAND-1);
    margin-top: -3.5rem;
}

h5.title {
    font-family: var(--GW-FONT-SERIF);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    font-size: var(--NFUM-FONT-SIZE-18);
    line-height: calc(var(--GW-SPACING-1)*15);
}

div.hardStopMessageContent {
    --gw-content-max-width: 100%;
    margin-top: var(--GW-SPACING-5);
    width: var(--gw-content-max-width);
    border-width:var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN);
    border-color: var(--GW-COLOR-STROKE);
    border-style: solid;
    padding: var(--GW-SPACING-5) var(--GW-SPACING-5) var(--GW-SPACING-5) var(--GW-SPACING-6);
    text-align: justify;
    line-height: 1.5rem;
}
div.hardStopMessageContent1, div.hardStopMessageContent2{
    padding-top: var(--GW-SPACING-3);
    text-align: justify;
}
.link {
    text-decoration: underline;
}

.phoneNumber {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

.descriptionContent {
    margin-bottom: 8px !important;
}