.fineArtContainer {
    > .title {
        font-size: var(--GW-FONT-SIZE) !important;
        padding: var(--GW-SPACING-6);
    }
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
    background: var(--NFUM-COLOR-LIGHT-GREY);
    padding: var(--GW-SPACING-5) var(--GW-SPACING-4);
    border-bottom: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY);
    font-size: var(--NFUM-FONT-SIZE-16);
    font-weight: 700;
    margin: 0;
}

.scheduleItemProperty {
    padding: var(--GW-SPACING-3) var(--GW-SPACING-6);
    margin-top: 0px;
    font-weight: 400;
    font-size: var(--GW-FONT-SIZE);

    :global(.jut__DatePickerField__readOnly) {
        font-weight: 400;
    padding: var(--GW-SPACING-3) var(--GW-SPACING-6);
    font-size: var(--GW-FONT-SIZE);
    }
}

.headerDescription {
    overflow: hidden;
}

