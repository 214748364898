@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);
$pageMaxWidth: 1500px;

.footerWrapper {
    width: 100%;
}

.footer {
    --nfum-footer-info-padding: var(--GW-SPACING-10) 12rem var(--GW-SPACING-10) 12rem;

    @include gw-breakpoint-down(phone) {
        --nfum-footer-info-padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-6);
        margin-top: var(--GW-SPACING-0);
    }
    @include gw-breakpoint-only(phoneWide) {
        --nfum-footer-info-padding: var(--GW-SPACING-10) var(--GW-SPACING-6) var(--GW-SPACING-10) var(--GW-SPACING-6);
        margin-top: var(--GW-SPACING-0);
    }
    @include gw-breakpoint-only(tablet) {
        --nfum-footer-info-padding: var(--GW-SPACING-10) 8rem var(--GW-SPACING-10) 8rem;
        margin-top: var(--GW-SPACING-0);
    }

    width: 100%;
    display: grid;
    min-height: 18rem;
    grid-gap: none;
    grid-template-rows: auto 5rem;
    grid-template-areas: "info" "bar";
    align-items: start;

    .footerInfoWrapper {
        background-color: var(--GW-COLOR-BACKGROUND-1);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .footerBarWrapper {
        background-color: var(--GW-BRAND-COLOR-1);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .footerInfo {
        max-width: $pageMaxWidth;
        height: 100%;
        padding: var(--nfum-footer-info-padding);
        background-color: var(--GW-COLOR-BACKGROUND-1);
        grid-area: info;
    }

    .footerBar {
        padding: 0 var(--GW-SPACING-6);
        max-width: $pageMaxWidth;
        width: 100%;
        background-color: var(--GW-BRAND-COLOR-1);
        grid-area: bar;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        z-index: 0;
        position: relative;
    }
}

.footerInfoGrid {
    font-size: var(--GW-FONT-SIZE-SUBTEXT);
    display: grid;
    grid-gap: calc(var(--GW-SPACING-5)*5);
    grid-template-columns: 2fr 3fr;
    grid-template-areas: "message links";
    align-items: start;

    @include gw-breakpoint-down(phone) {
        grid-template-areas: "links" "message";
        grid-template-columns: auto;
        grid-gap: var(--GW-SPACING-6);
    }
}

.footerInfoMessage {
    grid-area: message;
    line-height: 1.5rem;
}

.footerInfoLinks {
    font-weight: var(--GW-FONT-WEIGHT-MEDIUM);
    height: 100%;
    grid-area: links;
    border-left: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);
    padding-left: var(--GW-SPACING-10);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include gw-breakpoint-down(phone) {
        border-left: none;
        padding: 0;
        border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);
        padding-bottom: var(--GW-SPACING-6);
    }
}

.link {
    padding: 0.4rem;
    cursor: pointer;
}

.footerInfoLinks > * {
    flex: 1 1 10rem;
}

.footerBarImage {
    width: 10rem;
    height: 5rem;
    composes: nfum-logo-desktop from global;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: -11rem;
}

.footerImage {
    width: 170px;
    height: 100%;
    background-image: var(--gw-header-image);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    right: 130px;
}