@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
        phone: 768px,
        phoneWide: 1024px,
        tablet: 1500px
);

.radioButtonCard {
        padding-right: 40px;
        @include gw-breakpoint-only(phone){
                padding-right: 0px;
        }
        @include gw-breakpoint-only(phoneWide){
                padding-right: 0px;
        }
        @include gw-breakpoint-only(tablet){
                padding-right: 0px;
        }
        :global(.jut__RadioButtonCardField__moneyAmount) {
                display: none;
        }
}

.radioButtonCard > div:nth-child(2) > div {
        display: flex;
        grid-column-gap: var(--GW-LAYOUT-2);
        > div {
                width: -webkit-fill-available;
                display: block;
                padding: 1%;
                > div {
                        display: unset;
                        text-align: center;
                }
        }
        @include gw-breakpoint-only(phone){
                width:auto;
        }
        @include gw-breakpoint-only(phoneWide){
                width:auto;
        }
        @include gw-breakpoint-only(tablet){
                width:auto;
        }
}

.description {
        border-left: 1px solid var(--NFUM-COLOR-YELLOW-MAIN);
        background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
        padding: var(--GW-SPACING-5);
}