@import '~@jutro/theme/assets/sass/helpers';
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.card {
    background-color: var(--GW-COLOR-BACKGROUND-1);
    padding: 0 calc(var(--GW-SPACING-5) * 5) calc(var(--GW-SPACING-5) * 3) calc(var(--GW-SPACING-5) * 5);
    margin-bottom: var(--GW-SPACING-10);
}

.cardHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: var(--GW-SPACING-10) 0 calc(var(--GW-SPACING-6) * 2) 0;
}

.cardHeaderBorder {
    border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);
}

.cardHeader h5 {
    margin: 0 var(--GW-SPACING-5);
    font-family: var(--GW-FONT-SERIF);
    font-size: var(--GW-FONT-SIZE-MD);
    font-weight: var(--GW-FONT-WEIGHT-REGURAL);
    letter-spacing: 0.5px;
    line-height: var(--GW-LINE-HEIGHT);
    text-transform: none;
}

.cardContent {
    tbody > tr {
        height: 4rem;
    }
    tr:not(:nth-last-child(-n+2)) > td {
        border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);
    }
}

.excessTable {
    tr:last-child {
        font-weight: var(--GW-FONT-WEIGHT-BOLD);
    }
    div[role=rowgroup]:last-child {
        >div[role=row] {
            font-weight: var(--GW-FONT-WEIGHT-BOLD);
        }
    }
}

.nfumTableHeader {
    background-color: var(--NFUM-COLOR-FIELD-DISABLED);
    border-bottom: solid 1px var(--GW-BORDER-COLOR);
    padding: var(--GW-SPACING-5) var(--GW-SPACING-4);
    box-shadow: unset;
}

.nfumTableCell {
    vertical-align: inherit;
    border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE) !important;
    min-height: 3.5rem !important;
}

.nfumTableStepperCell {
    border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE) !important;
    > div > div {
        margin-bottom: var(--GW-SPACING-3) !important;
    }
}

.additionalCoverContainer {
    display: flex;
    justify-content: space-between;
}

.additionalCoverContainer:first-of-type {
    padding-top: 0;
    padding-bottom: 35px;
}

.additionalCoverContainer:last-of-type {
    padding-top: 0;
}

.additionalCoverContainerBorder {
    border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);
}

.additionalCover {
    margin-right: 24px;
    flex: 10;
    p {
        padding-top: 15px;
    }
}

.excessCover {
    margin-top: 1rem;
    h2 {
        font-weight: var(--GW-FONT-WEIGHT-BOLD);
        font-size: 0.825rem;
        margin-bottom: 1rem;
    }

}

.enableAdditionalCover {
    width: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.additionalCover h6 {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Meta Pro, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.5px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
}

.enableAdditionalCoverText {
    text-align: center;
}

.additionalText {
   padding-bottom: 5px;
}

.toggleCell {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toggleCellSwitch {
    margin: calc(2 * var(--GW-SPACING-5)) 0;
}

.selectCellDropdown {
    margin: calc(-1 * var(--GW-SPACING-1)) 0;
    >div {
        >div:first-child {
            display: none;
        }
    }
}

.hmeEmrgncyCovrTxtClaimPeriod {
    border-left: calc(var(--GW-BORDER-WIDTH)) solid var(--GW-BRAND-COLOR-1);
    padding: var(--GW-SPACING-3);
    margin-top: 1rem;
    background-color: #FFF5D1;
    
}

div[data-col=expander] {
    display: none !important;
}

div[role=table] {
    >div[role=rowgroup]:first-child {
        background-color: var(--NFUM-COLOR-FIELD-DISABLED);
        min-height: 3rem;
        border-bottom: transparent;
        box-shadow: unset;
    }
    >div[role=rowgroup] {
        >div[role=rowgroup] {
            >div[role=row] {
                height: fit-content;
                min-height: 3rem;
            }
        }
    }
}

.tableInfoIcon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
}

.tableCell {
    white-space: unset !important;
}

.tooltipContainer {
    border: 1px solid var(--NFUM-COLOR-LIGHT-GREY);
    border-left: 2px solid var(--NFUM-BORDER-LEFT-COLOR);

    .tooltipText {
        padding-top: var(--GW-SPACING-5);
        padding-left: var(--GW-SPACING-5);
        padding-right: var(--GW-SPACING-5);
    }

    .tooltipCloseButton {
        padding-left: var(--GW-SPACING-5);
    }
}

.spacing {
    padding-top: var(--GW-SPACING-5)
}

.showAllContentsCoverButton,
.showAllBuildingsCoverButton {
    padding: 0;
}

.spacingBottom {
    margin-bottom: 24px;
}

.personalExpenseDesc {
    margin-bottom: 24px !important;
    color: #2D2C2C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Meta Pro, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.compulsaryExcessCls {
    border-top: calc(var(--GW-BORDER-WIDTH)) solid var(--GW-BRAND-COLOR-1);
    padding: var(--GW-SPACING-3);
    background-color: #FFF5D1;
    padding-left: 15px;
}

.comprehensiveExcessCls {
    margin-top: 1rem;
}


.quoteContent {
    margin-top: 40px;
}

.typeOfPaymentToggle {
    box-shadow: none;
    border: none;
}

.updateQuote {
    &:global(.jut__Button__filled) {
        background-color: var(--NFUM-COLOR-DARK);
        color: var(--NFUM-COLOR-WHITE);
    }
    &:global(.jut__Button__filled:hover) {
        color: var(--NFUM-COLOR-DARK);
        background: var(--NFUM-COLOR-WHITE);
        border: none;
    }
    &:global(.jut__Button__filled:active) {
        background-color: var(--NFUM-COLOR-WHITE) !important;
        color: var(--NFUM-COLOR-DARK);
        border: none;
    }
    &:global(.jut__Button__filled:focus) {
        outline: 2px solid var(--NFUM-COLOR-DARK);
        outline-offset: 5px;
        color: var(--NFUM-COLOR-WHITE);
    }
    &:global(.jut__Button__filled:active:focus:hover) {
        outline: 2px solid var(--NFUM-COLOR-DARK);
        outline-offset: 5px;
        color: var(--NFUM-COLOR-WHITE) !important;
        background-color: var(--NFUM-COLOR-DARK) !important;
    }
}

.link {
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
}

.typeOfPaymentContainer {
    :global(.jut__ToggleField__toggleButton) {
        box-shadow: none;
        border: none;
        color: #2D2C2C;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Meta Pro, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 187.5% */
        letter-spacing: 0.5px;
        background: #FFF5D1;
    }

    :global(.jut__ToggleField__toggleButton:active:focus),
    :global(.jut__ToggleField__toggleButton:focus),
    :global(.jut__ToggleField__toggleButton:hover) {
        background: #FFF5D1;
        color: #2D2C2C;
        outline: none;
    }

    :global(.jut__ToggleField__toggleButton:hover) {
        box-shadow: none;
    }
}

h3.costInfo {
    color: #2D2C2C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Meta Pro, sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0.5px;
}

.stickyContainer {
    position: sticky;
    top: 0;
    z-index: 102;

    @include gw-breakpoint-up(phoneWide) {
        top: 96px;
    }
}

.quoteInfoContainer {
    background: #FFCB05;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.iptInfoContainer {
    display: flex;
    width: 259px;
    padding: 8px 0px;
    align-items: flex-start;
    border-top: 1px dashed var(--text-primary, #2D2C2C);
}

.iptText {
    color: #2D2C2C;
    font-feature-settings: 'clig' off, 'liga' off;
    text-align: center;
    font-family: Meta Pro, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.regulatoryTextContainer {
    background: #FFF5D1;
    display: flex;
    padding: 8px 72px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    color: #2D2C2C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Meta Pro, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.insuredPropertyDetails {
    background: #FFF;
    display: flex;
    padding: 16px 72px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

h5.propertyAddressTitle {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Meta Pro, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.propertyAddress {
    color: #2D2C2C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Meta Pro, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}