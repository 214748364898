@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);
$pageMaxWidth: 1500px;

:root {
  --gw-tablet-sidebar-width: 20rem;
}

html {
  --gw-header-image: url('../themes/nfumMainTheme/assets/logos/nfum-logo-desktop.svg');
  --gw-mobile-header-image: url('../themes/nfumMainTheme/assets/logos/nfum-logo-desktop.svg');
}

.notVisible {
  position: absolute;
  left: -9999px;
}

.qnbMain {
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  min-height: 100vh;
}

.qnbMainContent {
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr auto;

  &::before, &::after  {
    display: none
  }
}

.qnbContent {
  position: relative;
}

.faqPage {
  width: 70%;
  margin: auto;
}

// theme

$fonts-root: "../assets/fonts";

// ------------------------------------
//   GROUNDED CSS MODULES
// ------------------------------------
@import "../assets/fonts/fonts"; // nfum custom
@import "~@jutro/theme/groundedStyles";

// ----------------------------------------
//   BRANDING CSS MODULES
// ----------------------------------------
@import "../styles/branding/millennials-demo.scss";

.jut__FieldComponent__left .jut__FieldLabel__left {
  flex: 1 1 0;
  align-items: flex-start;
  height: auto;
  text-align: left;

  .jut__FieldLabel__fieldLabel {
    margin-right: var(--GW-SPACING-4);
  }
}

.jut__Accordion__accordionToggle {
  height: auto;
}

.headerLinkcomponent {
  padding-left: var(--GW-SPACING-8);
  text-align: right;
  color: var(--GW-TEXT-COLOR-3);
  span {
    font-size: var(--GW-FONT-SIZE-SM);
  }
  a {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    i, span {
      color: var(--GW-TEXT-COLOR-3);
      font-size: var(--GW-FONT-SIZE-H5);
    }
  }
  a:hover {
    text-decoration: underline;
    color: var(--GW-TEXT-COLOR-3);
  }
}

.jut__ModalNext__modal {
  --gw-modal-max-height: 90vh;
  > * {
    max-height: var(--gw-modal-max-height);
  }
}

.hidden {
  display: none;
}

.loadingViewContainer {
  height: 100vh;
}

.headerWrapper {
  background: white;
  position: sticky;
  z-index: 121;
  top: 0px;
  box-shadow: 0px 0px 1.6px rgba(0, 0, 0, 0);
  pointer-events: all;
  transition-duration: 600ms;
  transition-property: box-shadow, top;
  display: flex;
  justify-content: center;

  &.sticky {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  }
}

.headerAndInfoWrapper {
  width: 100%;
  max-width: $pageMaxWidth;
}

.jut__ModalHeader__modalHeader {
  min-height: 0rem;
}
.jut__ModalHeader__title{
 padding-top: 0.4rem;
}

.jut__NavBarItem__navBarItem{
    background-color: transparent !important;
}
.gw-header-img:hover {
   background-color: transparent !important;
}
.gw-header-img:focus {
    box-shadow: 0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);
  }
.gw-header-img::after{
    box-shadow: none !important;
    background: transparent !important;
 }
  
