@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.marketing {
    .marketingInfoBox {
        margin-bottom: var(--GW-SPACING-5);
        @include gw-breakpoint-down(tablet) {
            text-align: center;
        }
        @include gw-breakpoint-down(phoneWide) {
            display: inline-block;
            width: 100%;
            text-align: center;
        }
        img{
            object-fit: cover;
        }
    }
}