@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);

.ddContainer {
    border: 4px solid black;
    padding: 30px;
    padding-bottom: var(--GW-SPACING-3);
    padding-top: var(--GW-SPACING-8);

    @include gw-breakpoint-down(phone) {
        padding: 15px;
    }
}

.title {
    float: left;

    @include gw-breakpoint-down(phone) {
        max-width: 150px;
    }
}

.logo {
    float: right;
    width: 120px;
    margin-top: -15px;
}

ul.ddContent {
    margin-top: 70px;
    padding: 0px 15px;
    li p {
        font-size: var(--GW-FONT-SIZE-H4);
    }
}
