@import "~@jutro/theme/assets/sass/helpers";

button.jut__Button__filled {
    background-color: var(--NFUM-COLOR-YELLOW-MAIN);
    color: var(--NFUM-COLOR-GREY);
    border: var(--GW-BORDER-WIDTH) solid var(--NFUM-COLOR-YELLOW-MAIN);
    box-shadow: none;
}

button.jut__Button__filled:hover {
    color: var(--NFUM-COLOR-WHITE);
    background-color: var(--NFUM-COLOR-GREY);
    border-color: var(--NFUM-COLOR-GREY);
    box-shadow: none;

    .jut__Button__icon {
        color: var(--NFUM-COLOR-WHITE);
    }
}

button.jut__Button__filled:focus {
    box-shadow: none;
}

button.jut__Button__filled:active,
button.jut__Button__filled:active:focus,
button.jut__Button__filled:active:focus:hover {
    color: var(--NFUM-COLOR-YELLOW-MAIN);
    background-color: var(--NFUM-COLOR-DARK);
    border-color: var(--NFUM-COLOR-DARK);
    box-shadow: none;

    .jut__Button__icon {
        color: var(--NFUM-COLOR-YELLOW-MAIN);
    }
}

.jut__Button__filled:active:not(.jut__Button__disabled),
.jut__Button__filled:active:focus:not(.jut__Button__disabled),
.jut__Button__filled:active:focus:hover:not(.jut__Button__disabled),
.jut__Button__filled.jut__Button__activeKeypress:not(.jut__Button__disabled),
.jut__Button__filled.jut__Button__activeKeypress:focus:not(.jut__Button__disabled) {
    background-color: var(--NFUM-COLOR-DARK);

    .jut__Button__icon {
        color: var(--NFUM-COLOR-YELLOW-MAIN);
    }
}

button.jut__Button__filled.jut__Button__disabled,
button.jut__Button__filled.jut__Button__disabled:hover,
button.jut__Button__filled.jut__Button__disabled:active,
button.jut__Button__filled.jut__Button__disabled:active:focus,
button.jut__Button__filled.jut__Button__disabled:active:focus:hover {
    opacity: 1;
    background-color: var(--NFUM-COLOR-LIGHT-GREY);
    color: #5D5D5D;
    border: none;
    box-shadow: none;

    .jut__Button__icon {
        color: #5D5D5D;
    }
}