@import "~@jutro/theme/assets/sass/helpers";

.editLink {
  margin-top: var(--GW-SPACING-6);
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
}

.editQuoteButton {
  margin-left: var(--GW-SPACING-6);
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
}

.editPremiumLinksContainer {
  display: flex;

  a {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-BRAND-1);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  }

  a:hover {
    color: var(--GW-LINK-COLOR);
  }
}

.paddingBottomCls {
  padding-bottom: var(--GW-SPACING-6);
  font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

.customMessageDivCls {
  width: 100%;
  background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.customMessageHeaderCls {
  font-weight: 400;
  font-size: x-large;
}

.customMessageContactusCls {
  border: solid;
  padding-left: 5px;
  border-width: var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH-THIN) var(--GW-BORDER-WIDTH);
  border-color: var(--GW-BRAND-COLOR-2) var(--GW-BRAND-COLOR-2) var(--GW-BRAND-COLOR-2) var(--GW-COLOR-BRAND-1);
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 15px;
  max-width: 95%;
}

.preferredMethodCheckbox {
  box-shadow: var(--GW-SHADOW-2);
  padding: var(--GW-SPACING-4) var(--GW-SPACING-5);
  margin-bottom: var(--GW-SPACING-5);
  width: 70%;
  @include gw-breakpoint-only(phone){
    width: 100%;
    margin-top: var(--GW-SPACING-5) !important;
    margin-bottom: var(--GW-SHADOW-0);
}
}

.policySummaryAccordion {
  :global(.jut__FieldComponent__fieldComponent) {
    margin-top: 0;
  }
  :global(.jut__FieldLabel__fieldLabelContainer) {
    margin-bottom: 0;
  }
}

.riskLocationTitle {
  background: var(--NFUM-COLOR-LIGHT-GREY);
  padding: var(--GW-SPACING-5) var(--GW-SPACING-4);
  border-top: 1px solid var(--NFUM-COLOR-TABLE-BORDERS-GREY);
  font-size: var(--NFUM-FONT-SIZE-16);
  font-weight: 700;
  margin: 0;
  margin-bottom: 1rem;
}

.accordionContainer {
  background: white;
  &:global(.isOpen) {
    .accordionCardHeader {
      background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
    }
  }
}

.accordionCardTitleContainer {
  padding: 0;
}

.riskObjectInfoContainer {
  margin-bottom: var(--GW-SPACING-6);
  padding-top: 10px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.needToMakeChangeContainer {
  background-color: var(--GW-COLOR-BACKGROUND-1);
}

.needToMakeAChangeInfo {
  margin-bottom: var(--GW-SPACING-6);
}

.accordionCardBody {
  margin-top: var(--GW-SPACING-10);
  padding-bottom: 1rem;
  .valuableMargin {
    margin-top: 0;
  }

  .valuablePadding {
    padding-left: var(--GW-SPACING-5);
  }

  .valuableName {
    background-color: var(--NFUM-COLOR-FIELD-DISABLED);
    padding: var(--GW-SPACING-4);
    margin-top: var(--GW-SPACING-5);
    border-bottom: solid var(--GW-SPACING-1) var(--NFUM-COLOR-TABLE-BORDERS-GREY);
  }

  hr {
    border-color: var(--NFUM-COLOR-LIGHT-GREY);
  }
}

.specialConditionsContainer {
  div {
    background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
  }
  .policyScheduleLink {
    border-bottom: 1px solid;
    & span:hover {
      text-decoration: none;
    }
  }
}

.yourPolicyDocumentsHeading {
  padding: var(--GW-SPACING-10) 0;
  border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-COLOR-STROKE);
}

.bold {
  font-weight: var(--GW-FONT-WEIGHT-BOLD);
  font-size: 1rem;
}

.billingAddressStyle {
  font-weight: var(--GW-FONT-WEIGHT-REGULAR);
  padding-bottom: 1rem;
}

ul.confirmValidationCls {
  margin-top: 20px;
  margin-bottom: -0.5rem;
}
.acquisitionsHeading{
  padding-bottom: var(--GW-SPACING-5);
}
.acquisitionsDetails{
  padding-bottom: var(--GW-SPACING-5);
}
.valuablesInfoContainer {
  line-height: 2rem;
  letter-spacing: 0.5px;
}
.headerPadding {
  padding-top: 10px;
  padding-bottom: 10px;
}