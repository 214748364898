@import "~@jutro/theme/assets/sass/helpers";
$breakpoints: (
    phone: 768px,
    phoneWide: 1024px,
    tablet: 1500px
);
$pageMaxWidth: 1500px;

.container {
    margin-bottom: 20px;
}

.sessionTimeOutContainer {
    div {
      background-color: var(--NFUM-BODY-BACKGROUND-COLOR-1);
      border-bottom: none !important
    }
}

.refreshLink {
    font-family: Meta Pro, sans-serif;
    font-size: 1rem;
    text-decoration: underline;
}
