$loading-time: 60s;
$timer-height: 32px;

.timer {
    height: $timer-height;
    width: 100%;
    background-color: #D9D9D9;
}

@keyframes loading {
    0% {
        width: 0;
    } 100% {
        width: 100%;
    }
}

.timerIndicator { 
    width: 100%;
    height: $timer-height;
    background-color: var(--NFUM-COLOR-YELLOW-MAIN);
    animation-name: loading;
    animation-duration: $loading-time;
    animation-timing-function: linear;
}

.time {
    margin-top: -27px;
    padding-left: 10px;
    color: var(--NFUM-COLOR-GREY);
    font-family: var(--GW-FONT-SERIF);
    font-size: var(--NFUM-FONT-SIZE-14);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}